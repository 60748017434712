const upperCaseRegex = /[A-Z]/;
const lowerCaseRegex = /[a-z]/;
const numberRegex = /[0-9]/;
const symbolRegex = /[-#!$@£%^&*()_+|~=`{}[\]:";'<>?,./ ]/;

export const passwordStrengthValidation = (value: string) => {
  if (!value) {
    return true;
  }

  return upperCaseRegex.test(value) && lowerCaseRegex.test(value) && numberRegex.test(value) && symbolRegex.test(value);
};
