import { HederaNetwork } from '@bladelabs/blade-web3.js';

export enum Network {
  Mainnet = 'mainnet',
  Testnet = 'testnet',
}

export const HederaNetworks = {
  [Network.Mainnet]: HederaNetwork.Mainnet,
  [Network.Testnet]: HederaNetwork.Testnet,
};
