// Network fees defined on https://docs.hedera.com/hedera/networks/mainnet/fees
export const ACCOUNT_CREATE_USD = 0.05;
export const TOKEN_ASSOCIATE_USD = 0.05;
export const TOKEN_DISSOCIATE_USD = 0.05;
export const TOKEN_CREATE_USD = 1;
export const TOKEN_WITH_FEES_CREATE_USD = 2;
export const CONTRACT_CREATE_USD = 1;
export const GAS_USD = 0.000_000_0569;
export const NFT_TRANSFER_USD = 0.001;
export const CRYPTO_TRANSFER_USD = 0.0001;
export const SCHEDULE_CREATE = 0.01;
export const HTS_CONTRACT_CALL_MULTIPLIER = 1.2; // extra 20%, defined on https://docs.hedera.com/hedera/core-concepts/smart-contracts/gas-and-fees

// Custom fees
export const CONTRACT_MINT_NFT_USD = 0.065;
