import type { NetworkEnum } from '@/graphql/generatedTypesAndHooks';
import { ChainMap } from '@/models';
import { Horizon } from '@stellar/stellar-sdk';

export class StellarClientFactory {
  private static clientsCache: Map<NetworkEnum, Horizon.Server> = new Map();

  public static getClient(network: NetworkEnum): Horizon.Server {
    if (!this.clientsCache.has(network)) {
      const client = new Horizon.Server(ChainMap[network].mirrorNodeUrl);

      this.clientsCache.set(network, client);
    }

    return this.clientsCache.get(network);
  }
}
