<script setup lang="ts">
import { nftDropFeatures } from '@/components/Home/features';
import { nftDropCosts } from '@/components/Home/costs';
import ServiceAbout from '@/components/Service/ServiceAbout.vue';
import ServiceApproximate from '@/components/Service/ServiceApproximate.vue';
import ServiceFeatures from '@/components/Service/ServiceFeatures.vue';
import ServiceSupport from '@/components/Service/ServiceSupport.vue';
import ServiceUnfolds from '@/components/Service/ServiceUnfolds.vue';
import ServiceUseCaseCard from '@/components/Service/ServiceUseCaseCard.vue';
import ServiceVideo from '@/components/Service/ServiceVideo.vue';
import { WIZARD_SCREEN_ROUTE_NAME } from '@/router';
import { useCapabilitiesStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import CocktailsImage from '/public/home_banners/cocktails.png';
import GrazetteImage from '/public/home_banners/grazette.png';

const router = useRouter();
const { canCreateCampaign } = storeToRefs(useCapabilitiesStore());

const createCampaign = async () => {
  await router.push({ name: WIZARD_SCREEN_ROUTE_NAME });
};
</script>

<template>
  <div class="homeDetailNftDrop">
    <a-row :gutter="[16, 16]">
      <a-col :xs="24" :md="12" :lg="12" :xl="12">
        <ServiceAbout
          :title="$gettext('Launch Your NFT Event with Blade Console')"
          :subTitle="$gettext('Ready to launch? Enjoy a complimentary trial for your first 100 users.')"
        >
          <template #content>
            <p>
              {{
                $gettext(
                  `Begin your NFT distribution campaign with the Blade Console NFT Drop service. ` +
                    `Our intuitively designed setup process efficiently transforms your creative vision into a remarkable ` +
                    `and impactful digital event, allowing you to distribute your digital assets effortlessly. Experience ` +
                    `a seamless transition from concept to reality with our sophisticated tools and user-friendly ` +
                    `interface, tailored to enhance your campaign's success.`
                )
              }}
            </p>
          </template>
          <template #button>
            <a-button type="primary" size="large" @click="createCampaign()" :disabled="!canCreateCampaign">
              {{ $gettext('Get Started') }}
            </a-button>
          </template>
        </ServiceAbout>
        <ServiceFeatures :title="$gettext('Features Available')" :features="nftDropFeatures" />
      </a-col>
      <a-col :xs="24" :md="12" :lg="12" :xl="12">
        <ServiceVideo videoId="jqIV2U3l9hw" />
        <ServiceSupport />
        <ServiceApproximate :costs="nftDropCosts" />
      </a-col>
    </a-row>
    <ServiceUnfolds
      :title="$gettext('Here’s how it unfolds:')"
      :steps="[
        {
          name: $gettext('Set Up'),
          description: $gettext(`Craft your campaign's story.`),
        },
        {
          name: $gettext('Connect'),
          description: $gettext(`Securely link your wallet for NFT management.`),
        },
        {
          name: $gettext('Mint'),
          description: $gettext(`Create NFTs on-demand or use pre-minted tokens.`),
        },
        {
          name: $gettext('Distribute'),
          description: $gettext(`Roll out NFTs effortlessly to your audience.`),
        },
        {
          name: $gettext('Secure'),
          description: $gettext(`Implement robust anti-fraud measures for security.`),
        },
      ]"
    />

    <div class="homeDetailNftDrop__useCases">{{ $gettext('Use cases') }}</div>

    <a-row :gutter="[16, 16]">
      <a-col :xs="24" :md="12" :lg="12" :xl="12">
        <ServiceUseCaseCard title="Cocktails in the City NFT Campaign" :image="CocktailsImage">
          <p>
            {{
              $gettext(
                `Blade Console NFT Drop service enabled the bar to run an exclusive campaign called "Cocktails in the ` +
                  `City." The bar created a series of pre-minted NFTs, each depicting a different cocktail from their menu.`
              )
            }}
          </p>
        </ServiceUseCaseCard>
      </a-col>
      <a-col :xs="24" :md="12" :lg="12" :xl="12">
        <ServiceUseCaseCard title="Tia Grazette’s NFT Art Series" :image="GrazetteImage">
          <p>
            {{
              $gettext(
                `Utilizing the Blade Console, Tia Grazette launched an NFT art series that reflects her new style ` +
                  `influenced by graffiti and abstract expressionism. Her artwork, previously refined in oil paints from a ` +
                  `young age, transitioned beautifully into the digital realm, carrying with it her powerful messages for change.`
              )
            }}
          </p>
        </ServiceUseCaseCard>
      </a-col>
    </a-row>
  </div>
</template>

<style lang="less">
.homeDetailNftDrop {
  padding: 16px;

  &__useCases {
    margin: 20px 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
</style>
