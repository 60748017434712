import gettext from '@/gettext';
import type { Features } from '@/models';

const { $gettext } = gettext;

export const whiteLabelFeatures: Features[] = [
  {
    name: $gettext('UI Customization'),
    description: $gettext(
      'Design a wallet that mirrors your brand, with flexible UI configurations for a distinct look and feel.'
    ),
    isComingSoon: false,
    detailed: true,
    preview: true,
  },
  {
    name: $gettext('Branding'),
    description: $gettext('Integrate deep branding into every aspect of the wallet to enhance user engagement.'),
    isComingSoon: false,
    detailed: true,
    preview: true,
  },
  {
    name: $gettext('Dedicated Backend'),
    description: $gettext('Ensure smooth, scalable performance tailored to your needs.'),
    isComingSoon: false,
    detailed: true,
    preview: true,
  },
  {
    name: $gettext('Blade Wallet Codebase'),
    description: $gettext('Utilize our robust and secure foundation to start strong.'),
    isComingSoon: false,
    detailed: true,
    preview: true,
  },
  {
    name: $gettext('Technical Support'),
    description: $gettext('Access our expert team for seamless setup and integration.'),
    isComingSoon: false,
    detailed: true,
    preview: true,
  },
  {
    name: $gettext('Hardware Wallet Integration'),
    description: $gettext('Extend functionality with secure hardware wallet options.'),
    isComingSoon: false,
    detailed: true,
    preview: true,
  },
];
