import { NetworkEnum } from '@/graphql/generatedTypesAndHooks';
import { ChainMap, Hbar, Token, TokenType } from '@/models';
import { ApiService } from '@/services/ApiService';
import { NftService } from '@/services/NftService';
import type { TokenId } from '@hashgraph/sdk';
import { TokenType as SDKTokenType } from '@hashgraph/sdk';
import { AbstractTokenService } from '../AbstractTokenService';

class TokenService extends AbstractTokenService {
  chains: NetworkEnum[] = [NetworkEnum.HederaTestnet, NetworkEnum.HederaMainnet];

  public async getTokenInfo(
    tokenId: string | TokenId,
    network: NetworkEnum,
    contractId?: string
  ): Promise<Token | null> {
    if (tokenId === ChainMap[network].nativeTokenId) {
      return new Hbar(network);
    }

    let nftInfo = null;
    const apiTokenInfo = await ApiService.getTokenInfo(tokenId.toString(), network);

    if (!apiTokenInfo) return null;

    if (apiTokenInfo.type === SDKTokenType.NonFungibleUnique.toString()) {
      nftInfo = await NftService.getNftInfo(tokenId, network, null, null, contractId);

      if (nftInfo) {
        nftInfo.name = apiTokenInfo.name;
      }
    }

    let maxSupply = parseInt(apiTokenInfo.max_supply);
    maxSupply = maxSupply && !isNaN(maxSupply) ? maxSupply / 10 ** parseInt(apiTokenInfo.decimals) : Infinity;

    return new Token(
      apiTokenInfo.token_id!,
      network,
      apiTokenInfo.name,
      apiTokenInfo.symbol,
      apiTokenInfo.decimals,
      apiTokenInfo.type === SDKTokenType.FungibleCommon.toString() ? TokenType.Fungible : TokenType.NonFungible,
      apiTokenInfo.kyc_key && { type: apiTokenInfo.kyc_key._type as any, key: apiTokenInfo.kyc_key.key },
      parseFloat(apiTokenInfo.initial_supply) / 10 ** parseInt(apiTokenInfo.decimals),
      parseFloat(apiTokenInfo.total_supply) / 10 ** parseInt(apiTokenInfo.decimals),
      maxSupply,
      apiTokenInfo.treasury_account_id,
      apiTokenInfo.custom_fees,
      apiTokenInfo.pause_status === 'PAUSED',
      apiTokenInfo.deleted,
      null,
      nftInfo
    );
  }
}

export const HederaTokenService = new TokenService();
