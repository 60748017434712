import { getDecimalSeparator } from '@/utils/FormatHelpers';
import { Hbar, HbarUnit } from '@hashgraph/sdk';

export const getMinimalDecimals = (decimals: number | string): number => {
  const formatDecimals = Math.ceil((Number(decimals) || 1) / 4);

  if (+decimals <= 2) {
    return 2;
  }

  return formatDecimals;
};

export const floatToHbar = (num: number): Hbar => {
  const tinybars = num * Math.pow(10, 8);

  return Hbar.from(Math.floor(tinybars), HbarUnit.Tinybar);
};

export const getMaxNumberOfDecimals = (numbers: string[]): number => {
  const separator = getDecimalSeparator();

  return numbers.reduce((result, number) => {
    number = Number(number).toString(); // remove trailing zeros
    const [, decimal] = number.split(separator);
    const numberOfDecimals = decimal?.length || 0;

    return Math.max(result, numberOfDecimals);
  }, 0);
};
