<script setup lang="ts">
import ServiceBlock from '@/components/Service/ServiceBlock.vue';
import ServiceChainList from '@/components/Service/ServiceChainList.vue';
import { Chain, type Cost, type Costs } from '@/models';
import { computed, ref } from 'vue';
import BigNumber from 'bignumber.js';

interface Props {
  costs: Costs;
}

const props = defineProps<Props>();

const selectedChain = ref<Chain>(Chain.HEDERA);
const totalCost = computed(() => calculateTotalCost(props.costs[selectedChain.value]));

const calculateTotalCost = (costs: Array<Cost>) =>
  costs.reduce((acc, item) => BigNumber(acc).plus(BigNumber(item.cost)).toString(), '0');
</script>

<template>
  <ServiceBlock class="serviceApproximate" :title="$gettext('Approximate cost breakdown per user')">
    <ServiceChainList
      v-model:value="selectedChain"
      :chains="[
        { title: $gettext('Hedera'), chain: Chain.HEDERA, isComingSoon: !props.costs[Chain.HEDERA] },
        { title: $gettext('Stellar'), chain: Chain.STELLAR, isComingSoon: !props.costs[Chain.STELLAR] },
        { title: $gettext('Ethereum'), chain: Chain.ETHEREUM, isComingSoon: !props.costs[Chain.ETHEREUM] },
        { title: $gettext('Arbitrum'), chain: Chain.ARBITRUM, isComingSoon: !props.costs[Chain.ARBITRUM] },
      ]"
    />

    <a-row v-for="cost in props.costs[selectedChain]" :key="cost.name">
      <a-col flex="auto" class="serviceApproximate__cost">
        {{ cost.name }}
        <a-tag v-if="cost.label" :color="cost.label.color">{{ cost.label.name }}</a-tag>
      </a-col>
      <a-col flex="50px" class="text-right">${{ cost.cost }}</a-col>
    </a-row>
    <a-divider class="serviceApproximate__divider" />
    <a-row>
      <a-col flex="auto" class="serviceApproximate__total">{{ $gettext('Approximate total') }}</a-col>
      <a-col flex="50px" class="text-right">${{ totalCost }}</a-col>
    </a-row>
  </ServiceBlock>
</template>

<style lang="less">
.serviceApproximate {
  align-items: normal;
  gap: 8px;

  &__cost {
    color: rgba(0, 0, 0, 0.45);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  &__plan {
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    background: #fafafa;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 1px 8px;
  }

  &__divider {
    margin: 4px 0;
  }

  &__total {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
</style>
