import gettext from '@/gettext';
import { KeyType } from '@/models/KeyType';

const { $gettext } = gettext;

export const KeyTypeLabel = {
  [KeyType.ADMIN]: $gettext('Admin Key'),
  [KeyType.KYC]: $gettext('KYC'),
  [KeyType.FREEZE]: $gettext('Freeze'),
  [KeyType.WIPE]: $gettext('Wipe'),
  [KeyType.SUPPLY]: $gettext('Supply Key'),
  [KeyType.FEE]: $gettext('Fee Schedule'),
  [KeyType.PAUSE]: $gettext('Pause'),
};
