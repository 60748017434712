import { AbstractWalletConnectService } from '@/services/networks/AbstractWalletConnectService';
import { txSigMapToProtoSigMap } from '@/utils';
import {
  base64StringToSignatureMap,
  base64StringToUint8Array,
  HederaJsonRpcMethod,
  transactionBodyToBase64String,
  transactionToTransactionBody,
  type SignTransactionResult,
} from '@hashgraph/hedera-wallet-connect';
import { proto } from '@hashgraph/proto';
import type { TransactionResponseJSON } from '@hashgraph/sdk';
import { Transaction, TransactionResponse } from '@hashgraph/sdk';

export class HederaWalletConnectService extends AbstractWalletConnectService {
  async signAndExecuteTransaction<T extends Transaction>(transaction: T): Promise<TransactionResponse> {
    const result = await this.requestWrapper(
      this.client.request<TransactionResponseJSON>({
        topic: this.session.topic,
        chainId: this.chainId,
        request: {
          method: HederaJsonRpcMethod.SignAndExecuteTransaction,
          params: {
            signerAccountId: this.accountId,
            transactionList: Buffer.from(transaction.toBytes()).toString('base64'),
          },
        },
      })
    );

    return TransactionResponse.fromJSON(result);
  }

  async signTransaction<T extends Transaction>(transaction: T): Promise<T> {
    const nodeAccountId = transaction.nodeAccountIds[0];
    const transactionBody: proto.TransactionBody = transactionToTransactionBody(transaction, nodeAccountId);
    const transactionBodyBase64 = transactionBodyToBase64String(transactionBody);

    const { signatureMap } = await this.requestWrapper(
      this.client.request<SignTransactionResult['result']>({
        topic: this.session.topic,
        chainId: this.chainId,
        request: {
          method: HederaJsonRpcMethod.SignTransaction,
          params: {
            signerAccountId: this.accountId,
            transactionBody: transactionBodyBase64,
          },
        },
      })
    );

    const sigMap = base64StringToSignatureMap(signatureMap);

    // TODO: need to rewrite this whet fix will be available in hedera-wallet-connect
    // https://github.com/hashgraph/hedera-wallet-connect/issues/94
    const txSigMap = txSigMapToProtoSigMap(transaction.getSignatures());
    const mergedSigMap = proto.SignatureMap.create({
      sigPair: [...txSigMap.sigPair, sigMap.sigPair[0]],
    });

    const bodyBytes = base64StringToUint8Array(transactionBodyBase64);
    const bytes = proto.Transaction.encode({ bodyBytes, sigMap: mergedSigMap }).finish();
    return Transaction.fromBytes(bytes) as T;
  }

  async executeTransaction(transaction: Transaction): Promise<TransactionResponse> {
    const result = await this.requestWrapper(
      this.client.request<TransactionResponseJSON>({
        topic: this.session.topic,
        chainId: this.chainId,
        request: {
          method: HederaJsonRpcMethod.ExecuteTransaction,
          params: {
            transactionList: Buffer.from(transaction.toBytes()).toString('base64'),
          },
        },
      })
    );

    return TransactionResponse.fromJSON(result);
  }

  signAndExecuteQuery() {}

  async signMessage(message: string): Promise<proto.SignatureMap> {
    const { signatureMap } = await this.requestWrapper(
      this.client.request<SignTransactionResult['result']>({
        topic: this.session.topic,
        chainId: this.chainId,
        request: {
          method: HederaJsonRpcMethod.SignMessage,
          params: {
            signerAccountId: this.accountId,
            message: message,
          },
        },
      })
    );

    return base64StringToSignatureMap(signatureMap);
  }

  getNodeAddresses() {}
}
