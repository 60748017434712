export enum ErrorLogLvl {
  NONE = 'none',
  LOG = 'log',
  WARN = 'warn',
  ERROR = 'error',
}

export interface ErrorOptions {
  reportable?: boolean;
  logLvl?: ErrorLogLvl;
}

export class BldConsoleError extends Error {
  public logLvl: ErrorLogLvl = ErrorLogLvl.ERROR;
  public reportable: boolean = true;
  public originalError: Error | any;

  constructor(error?: Error, message?: string, options?: ErrorOptions) {
    super(message || error.message);
    this.originalError = error;
    this.logLvl = options?.logLvl || this.logLvl;
    this.reportable = options?.reportable || this.reportable;
  }
}
