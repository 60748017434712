import { Form } from 'ant-design-vue';
import type { FieldError, ValidateErrorEntity } from 'ant-design-vue/lib/form/interface';
import type { Props } from 'ant-design-vue/lib/form/useForm';
import type { Ref } from 'vue';

export function useValidateForm<Values>(
  modelRef: Props | Ref<Props>,
  rulesRef: Props | Ref<Props>
): () => Promise<{
  valid: boolean;
  values: Values;
  errorFields: FieldError[];
}> {
  const { validate } = Form.useForm(modelRef, rulesRef);

  return () =>
    new Promise((resolve) => {
      const resolveFunc = (values?: Values, errorEntity?: ValidateErrorEntity<Values>) =>
        resolve({
          valid: !errorEntity?.errorFields?.length,
          values: values || errorEntity!.values,
          errorFields: errorEntity?.errorFields || [],
        });

      validate()
        .then((r) => resolveFunc(r))
        .catch((e) => resolveFunc(undefined, e));
    });
}
