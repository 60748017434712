export const getCookie = (name: string): string | null => {
  const nameEQ = name + '=';
  const cookies = document.cookie.split(';');

  for (const cookie of cookies) {
    let c = cookie;

    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }

    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }

  return null;
};

export const setCookie = (name: string, value: string, expiresTime: number): void => {
  const date = new Date();
  date.setTime(date.getTime() + expiresTime);
  const expires = 'expires=' + date.toUTCString();
  document.cookie = `${name}=${value};${expires};path=/`;
};
