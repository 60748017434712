import { NetworkEnum } from '@/graphql/generatedTypesAndHooks';
import { ChainMap, Token, TokenType } from '../';

export class Xlm extends Token {
  constructor(network: NetworkEnum = NetworkEnum.StellarTestnet) {
    super(
      ChainMap[network].nativeTokenId,
      network,
      ChainMap[network].name,
      ChainMap[network].currency,
      ChainMap[network].decimals,
      TokenType.Fungible
    );
  }
}
