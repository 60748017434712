import gettext from '@/gettext';
import { Chain, type Costs } from '@/models';

const { $gettext } = gettext;

const networkFeesName = $gettext('Token association/account creation');
const bladeFeesName = $gettext('Blade Console services');
const bladeFeesLabel = {
  name: $gettext('Ninja Plan'),
  color: 'default',
};

export const tokenDropCosts: Costs = {
  [Chain.HEDERA]: [
    {
      name: networkFeesName,
      cost: '0.05',
    },
    {
      name: bladeFeesName,
      cost: '0.2',
      label: bladeFeesLabel,
    },
  ],
  [Chain.STELLAR]: [
    {
      name: networkFeesName,
      cost: '0.01',
    },
    {
      name: bladeFeesName,
      cost: '0.2',
      label: bladeFeesLabel,
    },
  ],
};
