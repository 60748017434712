import gettext from '@/gettext';
import type { Features } from '@/models';

const { $gettext } = gettext;

export const goToMarketFeatures: Features[] = [
  {
    name: $gettext('Strategy Development'),
    isComingSoon: false,
    detailed: true,
    preview: true,
  },
  {
    name: $gettext('Marketing & Branding'),
    isComingSoon: false,
    detailed: true,
    preview: true,
  },
  {
    name: $gettext('Community Engagement'),
    isComingSoon: false,
    detailed: true,
    preview: true,
  },
  {
    name: $gettext('Liquidity Strategies'),
    isComingSoon: false,
    detailed: true,
    preview: true,
  },
];
