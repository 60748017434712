<script setup lang="ts">
import { tokenStudioFeatures } from '@/components/Home/features';
import { tokenStudioCosts } from '@/components/Home/costs';
import ServiceAbout from '@/components/Service/ServiceAbout.vue';
import ServiceApproximate from '@/components/Service/ServiceApproximate.vue';
import ServiceFeatures from '@/components/Service/ServiceFeatures.vue';
import ServiceSupport from '@/components/Service/ServiceSupport.vue';
import ServiceUnfolds from '@/components/Service/ServiceUnfolds.vue';
import ServiceVideo from '@/components/Service/ServiceVideo.vue';
import { TOKEN_STUDIO_SCREEN_ROUTE_NAME } from '@/router';
import { useRouter } from 'vue-router';

const router = useRouter();

const open = async () => {
  await router.push({ name: TOKEN_STUDIO_SCREEN_ROUTE_NAME });
};
</script>

<template>
  <div class="homeDetailBladeLink">
    <a-row :gutter="[16, 16]">
      <a-col :xs="24" :md="12" :lg="12" :xl="12">
        <ServiceAbout
          :title="
            $gettext(
              `Unlock the potential of your assets with Blade Console's Token Studio. This platform simplifies ` +
                `token creation, offering you a seamless pathway from conceptual design to digital asset ` +
                `deployment. Craft and control your token ecosystem with precision`
            )
          "
        >
          <template #button>
            <a-button type="primary" size="large" @click="open()">
              {{ $gettext('Go to Token Studio') }}
            </a-button>
          </template>
        </ServiceAbout>
        <ServiceFeatures :title="$gettext('Components available')" :features="tokenStudioFeatures" />
      </a-col>
      <a-col :xs="24" :md="12" :lg="12" :xl="12">
        <ServiceVideo />
        <ServiceSupport />
        <ServiceApproximate :costs="tokenStudioCosts" />
      </a-col>
    </a-row>
    <ServiceUnfolds
      :title="$gettext('Here’s how it unfolds:')"
      :steps="[
        {
          name: $gettext('Token Configuration'),
          description: $gettext(`Specify attributes and narrative to define your token's characteristics.`),
        },
        {
          name: $gettext('Supply & Metadata Setup'),
          description: $gettext(`Adjust token supply and enrich with metadata to enhance brand alignment.`),
        },
        {
          name: $gettext('Permissions Management'),
          description: $gettext(`Establish access controls to safeguard token distribution and maintain integrity.`),
        },
        {
          name: $gettext('Asset Verification'),
          description: $gettext(`Demonstrate your token's backing with transparent proof-of-reserve.`),
        },
        {
          name: $gettext('Market Launch'),
          description: $gettext(
            `Deploy your token, ensuring it meets global compliance and is ready for transactions.`
          ),
        },
      ]"
    />
  </div>
</template>

<style lang="less">
.homeDetailBladeLink {
  padding: 16px;

  &__useCases {
    margin: 20px 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
</style>
