<script setup lang="ts">
import { requiredRule } from '@/validation';
import { reactive, ref, watch } from 'vue';
import { useGettext } from 'vue3-gettext';
import type { FormInstance } from 'ant-design-vue/es/form';
import type { Rule } from 'async-validator';
import type { LoginFormModel } from './LoginFormModel';

interface Props {
  value: LoginFormModel;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'update:value', value: LoginFormModel): void;
  (e: 'enter'): void;
}>();
const formRef = ref<FormInstance>();
const initialModel: LoginFormModel = {
  login: '',
  password: '',
};
const formModel = reactive<LoginFormModel>({ ...initialModel, ...props.value });
const { $gettext } = useGettext();

const rules = reactive<Record<keyof LoginFormModel, Rule[]>>({
  login: [requiredRule, { min: 5 }],
  password: [requiredRule, { min: 1 }],
});

watch(
  formModel,
  (v) => {
    emit('update:value', v);
  },
  { deep: true }
);
</script>

<template>
  <a-form hideRequiredMark ref="formRef" layout="vertical" validateTrigger="submit" :model="formModel" :rules="rules">
    <a-form-item>
      <a-input
        @keyup.enter="emit('enter')"
        v-model:value="formModel.login"
        type="text"
        :placeholder="$gettext('Email')"
        autocomplete="off"
        :data-testid="'login'"
      />
    </a-form-item>
    <a-form-item>
      <a-input-password
        @keyup.enter="emit('enter')"
        v-model:value="formModel.password"
        autocomplete="off"
        :placeholder="$gettext('Password')"
        :data-testid="'password'"
      />
    </a-form-item>
  </a-form>
</template>
