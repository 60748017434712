import { NetworkEnum } from '@/graphql/generatedTypesAndHooks';
import type { Token } from '@/models';
import { TokenServiceAdapter } from '@/services';
import { normalizeTokenId } from '@/utils';
import { defineStore } from 'pinia';
import { ref } from 'vue';

const SCHEMA_VERSION = 4; // Increment if token model changes and stored data reload is required

const expDuration = {
  [NetworkEnum.HederaMainnet]: 1209600000, // 14 days
  [NetworkEnum.HederaTestnet]: 86400000, // 24 hours
  [NetworkEnum.StellarMainnet]: 1209600000, // 14 days
  [NetworkEnum.StellarTestnet]: 86400000, // 24 hours
};

type TokenStoreObj = {
  token: Token;
  exp: number;
};

type TokensState = {
  [key in NetworkEnum]?: {
    [tokenId: string]: TokenStoreObj;
  };
} & { schemaVersion: number };

export const useTokenStore = defineStore(
  'tokenStore',
  () => {
    const storedTokens = ref<TokensState>({
      schemaVersion: SCHEMA_VERSION,
      [NetworkEnum.HederaMainnet]: {},
      [NetworkEnum.HederaTestnet]: {},
      [NetworkEnum.StellarMainnet]: {},
      [NetworkEnum.StellarTestnet]: {},
    });

    const getTokenInfo = async (
      tokenId: string,
      network: NetworkEnum,
      contractId?: string,
      forceFetch?: boolean
    ): Promise<Token> => {
      const storedToken = storedTokens.value[network][tokenId];

      if (!forceFetch && storedToken && !isExpired(storedToken)) {
        return storedToken.token;
      }

      return await fetchTokenInfo(tokenId, network, contractId);
    };

    const fetchTokenInfo = async (tokenId: string, network: NetworkEnum, contractId?: string): Promise<Token> => {
      const token = await TokenServiceAdapter.getTokenInfo(tokenId, network, contractId);

      if (token) {
        storedTokens.value[network][tokenId] = { token, exp: Date.now() + expDuration[network] };
      }

      return token;
    };

    const searchTokensByField = (
      search: string,
      field: 'tokenId' | 'symbol' | 'name',
      network: NetworkEnum
    ): TokenStoreObj[] => {
      if (field === 'tokenId' && normalizeTokenId(search)) {
        const tokenStoreObj = storedTokens.value[network][search];
        return tokenStoreObj ? [tokenStoreObj] : [];
      }

      return Object.values(storedTokens.value[network]).filter((tso) => tso.token[field].includes(search));
    };

    const searchTokens = async (search: string, network: NetworkEnum): Promise<Token[]> => {
      const result: Token[] = [];

      if (Object.values(storedTokens.value[network]).length) {
        const searchResults = [
          ...searchTokensByField(search, 'tokenId', network),
          ...searchTokensByField(search, 'symbol', network),
          ...searchTokensByField(search, 'name', network),
        ];
        const uniqSearchResults = [...new Set(searchResults)];
        await refetchExpiredTokens(uniqSearchResults);
        const tokens = uniqSearchResults.map((tso) => storedTokens.value[network][tso.token.tokenId].token);
        result.push(...tokens);
      }

      if (!result.length && normalizeTokenId(search)) {
        const token = await fetchTokenInfo(search, network);
        token && result.push(token);
      }

      return result;
    };

    const refetchExpiredTokens = async (tokens: TokenStoreObj[]) => {
      const expiredTokens = tokens.filter((tso) => isExpired(tso));
      await Promise.all(expiredTokens.map(async (tso) => await fetchTokenInfo(tso.token.tokenId, tso.token.network)));
    };

    const isExpired = (storedToken: TokenStoreObj): boolean => Date.now() > storedToken.exp;

    return {
      storedTokens,
      getTokenInfo,
      fetchTokenInfo,
      searchTokens,
    };
  },
  {
    persist: {
      serializer: {
        serialize: (value): string => {
          return JSON.stringify(value, (_, v) => {
            switch (true) {
              case v instanceof File:
                return null;
              case v === Infinity:
                return Infinity.toString();
              default:
                return v;
            }
          });
        },
        deserialize: (value) => {
          const state = JSON.parse(value, (_, v) => {
            switch (true) {
              case v === Infinity.toString():
                return Infinity;
              default:
                return v;
            }
          });

          if (!state.storedTokens?.schemaVersion || state.storedTokens.schemaVersion < SCHEMA_VERSION) {
            return {
              storedTokens: {
                schemaVersion: SCHEMA_VERSION,
                [NetworkEnum.HederaMainnet]: {},
                [NetworkEnum.HederaTestnet]: {},
              },
            };
          }

          return state;
        },
      },
    },
  }
);
