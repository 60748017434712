import type { OperationVariables } from '@apollo/client';
import type { ApolloError } from '@apollo/client/errors';
import type { UseQueryReturn } from '@vue/apollo-composable';

export function waitForApolloQuery<TResult, TVariables extends OperationVariables>({
  onResult,
  onError,
  forceDisabled,
}: Pick<UseQueryReturn<TResult, TVariables>, 'onResult' | 'onError' | 'forceDisabled'>) {
  if (forceDisabled.value) {
    return Promise.resolve<TResult | ApolloError | undefined>(undefined);
  }

  return new Promise<TResult | ApolloError | undefined>((res) => {
    const { off: offResult } = onResult((result) => {
      if (result.loading) {
        return;
      }

      res(result.data);
      setTimeout(offResult, 1);
    });
    const { off: offError } = onError((error) => {
      res(error);
      setTimeout(offError, 1);
    });
  });
}
