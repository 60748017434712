import type { NetworkEnum } from '@/graphql/generatedTypesAndHooks';
import { ChainMap } from '@/models';
import { proto } from '@hashgraph/proto';
import { Status, TokenId, TransactionId } from '@hashgraph/sdk';
import type SignatureMap from '@hashgraph/sdk/lib/transaction/SignatureMap';
import { camelCase, upperFirst } from 'lodash-es';

export const normalizeTokenId = (text: string): TokenId | null => {
  try {
    return TokenId.fromString(text);
  } catch (e) {
    return null;
  }
};

export const stringToStatus = (statusString: string): Status => {
  if (!statusString) {
    return null;
  }

  const normalizedStatus = upperFirst(camelCase(statusString));

  return Status[normalizedStatus];
};

export const formatTxIdForURL = (transactionId: string): string | null => {
  try {
    const { accountId, validStart } = TransactionId.fromString(transactionId);
    const [shard, realm, num, sss, nnn] = [
      accountId.shard,
      accountId.realm,
      accountId.num,
      validStart.seconds,
      validStart.nanos,
    ].map((v) => v.toString());
    return `${shard}.${realm}.${num}-${sss}-${nnn}`;
  } catch (e) {
    return null;
  }
};

export const getHashScanUrl = (
  network: NetworkEnum,
  type: 'token' | 'transaction' | 'account' | 'contract',
  id: string
): string => {
  return `https://hashscan.io/${ChainMap[network].nativeNetworkEnum}/${type}/${id}`;
};

export const txSigMapToProtoSigMap = (transactionSignatureMap: SignatureMap): proto.SignatureMap => {
  const signaturesList = Array.from(transactionSignatureMap.values());
  const sigPair = [
    ...new Set(
      signaturesList.flatMap((signature) =>
        Array.from(signature.keys()).map((key) => key._toProtobufSignature(signature.get(key)))
      )
    ),
  ];

  return proto.SignatureMap.create({ sigPair });
};
