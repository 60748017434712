import { NetworkEnum } from '@/graphql/generatedTypesAndHooks';
import type { NetworkRate, RateData } from '@/services';
import { RateService } from '@/services';
import { defineStore } from 'pinia';
import { ref } from 'vue';

const expDuration = {
  [NetworkEnum.HederaMainnet]: 3600000, // 1 hour
  [NetworkEnum.HederaTestnet]: 3600000, // 1 hour
};

type RateStoreObj = {
  rate: RateData;
  exp: number;
};

type RatesState = {
  [key in NetworkEnum]?: {
    [tokenId: string]: RateStoreObj;
  };
};

export const useRateStore = defineStore('rateStore', () => {
  const cryptoExchangeRates = ref<RatesState>({
    [NetworkEnum.HederaMainnet]: {},
    [NetworkEnum.HederaTestnet]: {},
  });

  const networkHbarRate = ref<Partial<Record<NetworkEnum, NetworkRate>>>({
    [NetworkEnum.HederaMainnet]: null,
    [NetworkEnum.HederaTestnet]: null,
  });

  const getUSDRate = async (tokenId: string, network: NetworkEnum): Promise<number> => {
    const storedRate = cryptoExchangeRates.value[network][tokenId];

    if (Object.keys(cryptoExchangeRates.value[network]).length && !storedRate) {
      return 0;
    }

    if (storedRate && !isExpired(storedRate)) {
      return storedRate.rate.usdPrice;
    }

    const rate = await fetchRate(tokenId, network);

    return rate ? rate.usdPrice : 0;
  };

  const getHBARRate = async (tokenId: string, network: NetworkEnum): Promise<string> => {
    const storedRate = cryptoExchangeRates.value[network][tokenId];

    if (Object.keys(cryptoExchangeRates.value[network]).length && !storedRate) {
      return '0';
    }

    if (storedRate && !isExpired(storedRate)) {
      return storedRate.rate.hbarPrice;
    }

    const rate = await fetchRate(tokenId, network);

    return rate ? rate.hbarPrice : '0';
  };

  const getNetworkRate = async (network: NetworkEnum): Promise<number> => {
    const storedRate = networkHbarRate.value[network];

    if (storedRate && !isExpired(storedRate)) {
      return storedRate.rate;
    }

    const rate = await fetchNetworkRate(network);

    return rate ? rate.rate : 0;
  };

  const fetchRate = async (tokenId: string, network: NetworkEnum): Promise<RateData> => {
    const apiRates = await RateService.getRates(network);

    if (apiRates?.length) {
      apiRates.forEach((rate) => {
        cryptoExchangeRates.value[network][rate.tokenId] = {
          rate,
          exp: Date.now() + expDuration[network],
        };
      });
    }

    return cryptoExchangeRates.value[network][tokenId]?.rate;
  };

  const fetchNetworkRate = async (network: NetworkEnum): Promise<NetworkRate> => {
    const networkRate = await RateService.getNetworkRate(network);

    if (networkRate) {
      networkHbarRate.value[network] = networkRate;
    }

    return networkRate;
  };

  const isExpired = (storedRate: { exp: number }): boolean => Date.now() > storedRate.exp;

  return {
    cryptoExchangeRates,
    networkHbarRate,
    getUSDRate,
    getHBARRate,
    getNetworkRate,
  };
});
