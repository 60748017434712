import gettext from '@/gettext';
import type { Features } from '@/models';

const { $gettext } = gettext;

export const sdkFeatures: Features[] = [
  {
    name: $gettext('Seamless Integration'),
    description: $gettext('Incorporate blockchain features with ease using our comprehensive guides and code samples.'),
    isComingSoon: false,
    detailed: true,
    preview: true,
  },
  {
    name: $gettext('Multi-Platform Support'),
    description: $gettext('Deploy solutions across web, mobile, and desktop environments.'),
    isComingSoon: false,
    detailed: true,
    preview: true,
  },
  {
    name: $gettext('Scalability'),
    description: $gettext(
      'Designed with scalability in mind, our SDK adapts to your growing user base and expanding service offerings, ensuring you’re always ahead of the curve.'
    ),
    isComingSoon: false,
    detailed: true,
    preview: true,
  },
  {
    name: $gettext('Customizable Toolkit'),
    description: $gettext('Tailor the SDK with modules that match your application requirements.'),
    isComingSoon: false,
    detailed: true,
    preview: true,
  },
  {
    name: $gettext('Real-Time Support'),
    description: $gettext('Receive immediate assistance from our expert team for any integration challenges.'),
    isComingSoon: false,
    detailed: true,
    preview: true,
  },
  {
    name: $gettext('Unreal engine'),
    isComingSoon: true,
    detailed: false,
    preview: true,
  },
];
