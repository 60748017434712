import type { NetworkEnum } from '@/graphql/generatedTypesAndHooks';
import type {
  APIAccountInfo,
  APIAccountToken,
  APIAccountTokens,
  APIContractResultsLogs,
  APICryptoAllowance,
  APICryptoAllowances,
  APIExchangeRates,
  APINfts,
  APITokenAllowance,
  APITokenAllowances,
  APITransactionData,
  APITransactions,
  APITransactionsRequestOptions,
} from '@/models';

import { HederaClientFactory } from '@/client';
import type { APITokenInfo } from '@/models';
import { ExceptionHandler } from '@/services/ExceptionHandler';
import { ContractId } from '@hashgraph/sdk';
import type { AxiosResponse } from 'axios';

export class ApiService {
  static getTokenInfo(tokenId: string, network: NetworkEnum): Promise<APITokenInfo | null> {
    return HederaClientFactory.getClient(network)
      .get<APITokenInfo>(`api/v1/tokens/${tokenId}`)
      .then((res) => res.data)
      .catch((error) => {
        ExceptionHandler.handleError(error);
        return null;
      });
  }

  static async getNftInfo(
    tokenId: string,
    network: NetworkEnum,
    nextPage?: string | null,
    accountId?: string,
    serial?: string,
    limit?: number
  ): Promise<APINfts> {
    let response: AxiosResponse<APINfts>;

    if (!nextPage) {
      let url = `api/v1/tokens/${tokenId}/nfts`;
      const params = new URLSearchParams();
      params.set('limit', (limit || 100).toString());
      params.set('order', 'asc');
      if (accountId) {
        params.set('account.id', accountId);
      }
      if (serial) {
        params.set('serialnumber', serial);
      }
      url += `?${params.toString()}`;
      response = await HederaClientFactory.getClient(network).get<APINfts>(url);
    } else {
      response = await HederaClientFactory.getClient(network).get<APINfts>(nextPage);
    }

    return response.data;
  }

  static async getAccountTokens(
    accountId: string,
    network: NetworkEnum,
    nextPage?: string | null,
    tokenId?: string
  ): Promise<APIAccountTokens> {
    let response: AxiosResponse<APIAccountTokens>;

    if (!nextPage) {
      let url = `api/v1/accounts/${accountId}/tokens`;
      const params = new URLSearchParams();
      params.set('limit', '100');
      if (tokenId) {
        params.set('token.id', `eq:${tokenId}`);
      }
      url += `?${params.toString()}`;
      response = await HederaClientFactory.getClient(network).get<APIAccountTokens>(url);
    } else {
      response = await HederaClientFactory.getClient(network).get<APIAccountTokens>(nextPage);
    }

    return response.data;
  }

  static async getAllAccountTokens(accountId: string, network: NetworkEnum): Promise<APIAccountToken[]> {
    let nextPage = null;
    const result = [];

    do {
      const {
        tokens,
        links: { next },
      }: APIAccountTokens = await ApiService.getAccountTokens(accountId, network, nextPage);
      nextPage = next ? next.slice(1) : next;
      result.push(...tokens);
    } while (nextPage !== null);

    return result;
  }

  static async getAccountHBARBalance(accountId: string, network: NetworkEnum): Promise<number> {
    let response;

    try {
      response = await HederaClientFactory.getClient(network).get(`api/v1/balances?account.id=${accountId}`);
    } catch (error: any) {
      ExceptionHandler.handleError(error);
    }

    return response.data.balances[0].balance;
  }

  static getAccountInfo(accountId: string, network: NetworkEnum): Promise<APIAccountInfo> {
    return HederaClientFactory.getClient(network)
      .get<APIAccountInfo>(`api/v1/accounts/${accountId}`)
      .then((res) => res.data);
  }

  static async getAccountCryptoAllowances(
    accountId: string,
    network: NetworkEnum,
    nextPage?: string | null,
    spenderId?: string
  ): Promise<APICryptoAllowances> {
    let response: AxiosResponse<APICryptoAllowances>;

    if (!nextPage) {
      let url = `api/v1/accounts/${accountId}/allowances/crypto`;
      const params = new URLSearchParams();
      params.set('limit', '100');
      if (spenderId) {
        params.set('spender.id', `eq:${spenderId}`);
      }
      url += `?${params.toString()}`;
      response = await HederaClientFactory.getClient(network).get<APICryptoAllowances>(url);
    } else {
      response = await HederaClientFactory.getClient(network).get<APICryptoAllowances>(nextPage);
    }

    return response.data;
  }

  static async getAllAccountCryptoAllowancesBySpenderId(
    accountId: string,
    spenderId: string,
    network: NetworkEnum
  ): Promise<APICryptoAllowance[]> {
    let nextPage = null;
    const result = [];

    do {
      const {
        allowances,
        links: { next },
      }: APICryptoAllowances = await ApiService.getAccountCryptoAllowances(accountId, network, nextPage, spenderId);
      nextPage = next ? next.slice(1) : next;
      result.push(...allowances);
    } while (nextPage !== null);

    return result;
  }

  static async getAccountTokenAllowances(
    accountId: string,
    network: NetworkEnum,
    nextPage?: string | null,
    spenderId?: string
  ): Promise<APITokenAllowances> {
    let response: AxiosResponse<APITokenAllowances>;

    if (!nextPage) {
      let url = `api/v1/accounts/${accountId}/allowances/tokens`;
      const params = new URLSearchParams();
      params.set('limit', '100');
      if (spenderId) {
        params.set('spender.id', `eq:${spenderId}`);
      }
      url += `?${params.toString()}`;
      response = await HederaClientFactory.getClient(network).get<APITokenAllowances>(url);
    } else {
      response = await HederaClientFactory.getClient(network).get<APITokenAllowances>(nextPage);
    }

    return response.data;
  }

  static async getAllAccountTokenAllowancesBySpenderId(
    accountId: string,
    spenderId: string,
    network: NetworkEnum
  ): Promise<APITokenAllowance[]> {
    let nextPage = null;
    const result = [];

    do {
      const {
        allowances,
        links: { next },
      }: APICryptoAllowances = await ApiService.getAccountTokenAllowances(accountId, network, nextPage, spenderId);
      nextPage = next ? next.slice(1) : next;
      result.push(...allowances);
    } while (nextPage !== null);

    return result;
  }

  static getContractResultsLogs(network: NetworkEnum, contractId: string): Promise<APIContractResultsLogs> {
    return HederaClientFactory.getClient(network)
      .get<APIContractResultsLogs>(`api/v1/contracts/${contractId.toString()}/results/logs?order=asc`)
      .then((res) => res.data)
      .catch((error) => {
        ExceptionHandler.handleError(error);
        return { logs: [] };
      });
  }

  static async getAccountNfts(
    accountId: string,
    network: NetworkEnum,
    nextPage?: string | null,
    tokenId?: string,
    limit?: number
  ): Promise<APINfts> {
    let response: AxiosResponse<APINfts>;

    if (!nextPage) {
      let url = `api/v1/accounts/${accountId}/nfts`;
      const params = new URLSearchParams();
      params.set('limit', (limit || 100).toString());
      params.set('order', 'asc');
      if (tokenId) {
        params.set('token.id', tokenId);
      }
      url += `?${params.toString()}`;
      response = await HederaClientFactory.getClient(network).get<APINfts>(url);
    } else {
      response = await HederaClientFactory.getClient(network).get<APINfts>(nextPage);
    }

    return response.data;
  }

  static getTransaction(transactionId: string, network: NetworkEnum): Promise<APITransactionData[]> {
    return HederaClientFactory.getClient(network)
      .get(`api/v1/transactions/${transactionId}`)
      .then((res) => res.data?.transactions || [])
      .catch((err) => {
        ExceptionHandler.handleError(err);
        return [];
      });
  }

  static async getAccountTransactions(
    accountId: string,
    network: NetworkEnum,
    nextPage?: string | null,
    options?: APITransactionsRequestOptions
  ): Promise<APITransactions> {
    let response: AxiosResponse<APITransactions>;

    if (!nextPage) {
      let url = `api/v1/transactions`;
      const params = new URLSearchParams();

      params.set('account.id', accountId);
      params.set('limit', (options?.limit || 100).toString());
      params.set('order', options?.order || 'desc');

      options?.transactionType && params.set('transactiontype', options.transactionType);
      options?.result && params.set('result', options.result);
      options?.type && params.set('type', options.type);

      url += `?${params.toString()}`;

      response = await HederaClientFactory.getClient(network).get<APITransactions>(url);
    } else {
      response = await HederaClientFactory.getClient(network).get<APITransactions>(nextPage);
    }

    return response.data;
  }

  static async getAllAccountTransactions(
    accountId: string,
    network: NetworkEnum,
    options?: APITransactionsRequestOptions
  ): Promise<APITransactionData[]> {
    let nextPage = null;
    const result = [];

    do {
      const {
        transactions,
        links: { next },
      }: APITransactions = await ApiService.getAccountTransactions(accountId, network, nextPage, options);
      nextPage = next ? next.slice(1) : next;
      result.push(...transactions);
    } while (nextPage !== null);

    return result;
  }

  static getExchangeRate(network: NetworkEnum): Promise<APIExchangeRates> {
    return HederaClientFactory.getClient(network)
      .get<APIExchangeRates>(`api/v1/network/exchangerate`)
      .then((res) => res.data);
  }

  static callContract(network: NetworkEnum, contractId: string, data: string): Promise<{ result: string }> {
    return HederaClientFactory.getClient(network)
      .post<{ result: string }>('api/v1/contracts/call', {
        to: ContractId.fromString(contractId).toSolidityAddress(),
        data,
      })
      .then((res) => res.data);
  }
}
