import HomeDetailBladeLink from '@/components/Home/HomeDetail/HomeDetailBladeLink.vue';
import HomeDetailCombinedDrop from '@/components/Home/HomeDetail/HomeDetailCombinedDrop.vue';
import HomeDetailLoyalty from '@/components/Home/HomeDetail/HomeDetailLoyalty.vue';
import HomeDetailMultiSig from '@/components/Home/HomeDetail/HomeDetailMultiSig.vue';
import HomeDetailNftDrop from '@/components/Home/HomeDetail/HomeDetailNftDrop.vue';
import HomeDetailSdk from '@/components/Home/HomeDetail/HomeDetailSdk.vue';
import HomeDetailTokenDrop from '@/components/Home/HomeDetail/HomeDetailTokenDrop.vue';
import HomeDetailTokenStudio from '@/components/Home/HomeDetail/HomeDetailTokenStudio.vue';
import HomeDetailWhiteLabel from '@/components/Home/HomeDetail/HomeDetailWhiteLabel.vue';
import HomeUseCaseKarate from '@/components/Home/HomeUseCase/HomeUseCaseKarate.vue';
import HomeUseCaseLoyalty from '@/components/Home/HomeUseCase/HomeUseCaseLoyalty.vue';
import HomeUseCaseSunDao from '@/components/Home/HomeUseCase/HomeUseCaseSunDao.vue';
import HomeUseCaseSwiss from '@/components/Home/HomeUseCase/HomeUseCaseSwiss.vue';
import { LAST_ACTIVITY_KEY } from '@/config';
import { GAEvents } from '@/GAEvents';
import gettext from '@/gettext';
import { Capability } from '@/graphql/generatedTypesAndHooks';
import BasicLayout from '@/layouts/BasicLayout.vue';
import DAppWizardLayout from '@/layouts/DAppWizardLayout.vue';
import FullWidthLayout from '@/layouts/FullWidthLayout.vue';
import SafeAnonymousLayout from '@/layouts/SafeAnonymousLayout.vue';
import UserLayout from '@/layouts/UserLayout.vue';
import WizardLayout from '@/layouts/WizardLayout.vue';
import { User, UserRole } from '@/models';
import { useUserStore } from '@/stores';
import { getCookie } from '@/utils';
import DropFailureView from '@/views/DropFailureView.vue';
import DropSuccessView from '@/views/DropSuccessView.vue';
import CheckoutView from '@/views/FullWidthLayout/CheckoutView.vue';
import DisclaimerView from '@/views/FullWidthLayout/DisclaimerView.vue';
import RequestResetPasswordView from '@/views/FullWidthLayout/RequestResetPasswordView.vue';
import ResetPasswordView from '@/views/FullWidthLayout/ResetPasswordView.vue';
import UserInviteView from '@/views/FullWidthLayout/UserInviteView.vue';
import LogoutView from '@/views/LogoutView.vue';
import NotFoundView from '@/views/NotFoundView.vue';
import LoginView from '@/views/UserLayout/LoginView.vue';
import { getAuth } from 'firebase/auth';
import { createRouter, createWebHistory } from 'vue-router';

export const HOME_SCREEN_ROUTE_NAME = 'home';
export const DASHBOARD_SCREEN_ROUTE_NAME = 'dashboard';
export const CAMPAIGNS_SCREEN_ROUTE_NAME = 'campaigns';
export const TOKEN_STUDIO_SCREEN_ROUTE_NAME = 'token-studio';
export const TOKEN_STUDIO_WIZARD_SCREEN_ROUTE_NAME = 'token-studio-wizard';
export const SDK_SCREEN_ROUTE_NAME = 'sdk';
export const DAPP_SCREEN_ROUTE_NAME = 'dapp';
export const LOGIN_SCREEN_ROUTE_NAME = 'login';
export const LOGOUT_SCREEN_ROUTE_NAME = 'logout';
export const WIZARD_SCREEN_ROUTE_NAME = 'wizard';
export const SAFE_WIZARD_SCREEN_ROUTE_NAME = 'multi-sig-wizard';
export const SAFE_SIGN_SCREEN_ROUTE_NAME = 'multi-sig-sign';
export const SAFE_SCREEN_ROUTE_NAME = 'multi-sig-detail';
export const SAFE_DASHBOARD_SCREEN_ROUTE_NAME = 'multi-sig';
export const DROP_SUCCESS_SCREEN_ROUTE_NAME = 'drop-success';
export const DROP_FAILURE_SCREEN_ROUTE_NAME = 'drop-failure';
export const BILLING_SCREEN_ROUTE_NAME = 'billing';
export const PRICING_SCREEN_ROUTE_NAME = 'pricing';
export const SETTINGS_SCREEN_ROUTE_NAME = 'settings';
export const ABOUT_SCREEN_ROUTE_NAME = 'about';
export const NOT_FOUND_ROUTE_NAME = 'not-found';
export const DISCLAIMER_ROUTE_NAME = 'disclaimer';
export const CHECKOUT_ROUTE_NAME = 'checkout';
export const USER_INVITE_ROUTE_NAME = 'user-invite';
export const REQUEST_RESET_PASSWORD_ROUTE_NAME = 'request-reset-password';
export const RESET_PASSWORD_ROUTE_NAME = 'reset-password';
export const SIGN_UP_ROUTE_NAME = 'signup';
export const HOME_NFT_ROUTE_NAME = 'home-nft';
export const HOME_FT_ROUTE_NAME = 'home-ft';
export const HOME_COMBINED_ROUTE_NAME = 'home-combined';
export const HOME_BLADE_LINK_ROUTE_NAME = 'home-blade-link';
export const HOME_BLADE_LINK_SUN_DAO_ROUTE_NAME = 'home-blade-link-sun-dao';
export const HOME_WHITE_LABEL_ROUTE_NAME = 'home-white-label';
export const HOME_WHITE_LABEL_SWISS_ROUTE_NAME = 'home-white-label-swiss';
export const HOME_SDK_ROUTE_NAME = 'home-sdk';
export const HOME_SDK_KARATE_ROUTE_NAME = 'home-sdk-karate';
export const HOME_TOKEN_STUDIO_ROUTE_NAME = 'home-token-studio';
export const HOME_MULTI_SIGNATURE_ROUTE_NAME = 'home-multi-signature';
export const HOME_LOYALTY_ROUTE_NAME = 'home-loyalty';
export const HOME_LOYALTY_CARD_ROUTE_NAME = 'home-loyalty-card';

export const DAPP_WIZARD_CAMPAIGN = 'dapp-wizard-campaign';
export const DAPP_WIZARD_CHAIN = 'dapp-wizard-chain';
export const DAPP_WIZARD_CONNECT = 'dapp-wizard-connect';
export const DAPP_WIZARD_SETUP = 'dapp-wizard-setup';
export const DAPP_WIZARD_SETUP_FT = 'dapp-wizard-setup-ft';
export const DAPP_WIZARD_SETUP_NFT = 'dapp-wizard-setup-nft';
export const DAPP_WIZARD_SETUP_MINT = 'dapp-wizard-setup-mint';
export const DAPP_WIZARD_MINT = 'dapp-wizard-mint';
export const DAPP_WIZARD_MINT_CONFIGURE = 'dapp-wizard-mint-configure';
export const DAPP_WIZARD_MINT_PREVIEW = 'dapp-wizard-mint-preview';
export const DAPP_WIZARD_ALLOWANCE = 'dapp-wizard-allowance';
export const DAPP_WIZARD_ALLOWANCE_FT = 'dapp-wizard-allowance-ft';
export const DAPP_WIZARD_ALLOWANCE_NFT = 'dapp-wizard-allowance-nft';
export const DAPP_WIZARD_SECURITY = 'dapp-wizard-security';
export const DAPP_WIZARD_REVIEW = 'dapp-wizard-review';
export const DAPP_WIZARD_SHARE = 'dapp-wizard-share';

const WIZARD_ROUTE = 'wizard';

const { $gettext } = gettext;

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior() {
    const scrollPosition = document.querySelector('#content_scroller')?.scrollTop;

    if (scrollPosition) {
      document.querySelector('#content_scroller').scrollTop = 0;
    }
  },
  routes: [
    {
      path: '/',
      component: BasicLayout,
      redirect: () => '/home',
      children: [
        {
          path: '/home',
          name: HOME_SCREEN_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeView.vue'),
        },
        {
          path: '/home/nft',
          name: HOME_NFT_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeDetailView.vue'),
          meta: {
            title: $gettext('NFT Drop'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/nft', breadcrumbName: $gettext('NFT Drop') },
            ],
            component: HomeDetailNftDrop,
            sidebarKey: '/home',
          },
        },
        {
          path: '/home/ft',
          name: HOME_FT_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeDetailView.vue'),
          meta: {
            title: $gettext('Fungible Token Drop'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/ft', breadcrumbName: $gettext('FT Drop') },
            ],
            component: HomeDetailTokenDrop,
            sidebarKey: '/home',
          },
        },
        {
          path: '/home/combined',
          name: HOME_COMBINED_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeDetailView.vue'),
          meta: {
            title: $gettext('Combined Token Drop'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/combined', breadcrumbName: $gettext('Combined Token Drop') },
            ],
            component: HomeDetailCombinedDrop,
            sidebarKey: '/home',
          },
        },
        {
          path: '/home/blade-link',
          name: HOME_BLADE_LINK_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeDetailView.vue'),
          meta: {
            title: $gettext('BladeLink: Customized Onboarding for Optimal Engagement'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/blade-link', breadcrumbName: $gettext('BladeLink') },
            ],
            component: HomeDetailBladeLink,
            sidebarKey: '/home',
          },
        },
        {
          path: '/home/blade-link/sun-dao',
          name: HOME_BLADE_LINK_SUN_DAO_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeUseCaseView.vue'),
          meta: {
            title: $gettext('BladeLink: SunDao'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/blade-link', breadcrumbName: $gettext('BladeLink') },
              { path: '/home/blade-link/sun-dao', breadcrumbName: $gettext('SunDao') },
            ],
            component: HomeUseCaseSunDao,
            sidebarKey: '/home',
          },
        },
        {
          path: '/home/white-label',
          name: HOME_WHITE_LABEL_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeDetailView.vue'),
          meta: {
            title: $gettext('White-Label Wallet Service'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/white-label', breadcrumbName: $gettext('White-Label Wallet') },
            ],
            component: HomeDetailWhiteLabel,
            sidebarKey: '/home',
          },
        },
        {
          path: '/home/white-label/swiss',
          name: HOME_WHITE_LABEL_SWISS_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeUseCaseView.vue'),
          meta: {
            title: $gettext('White Label Wallet: Swiss Blade'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/white-label', breadcrumbName: $gettext('White-Label Wallet') },
              { path: '/home/white-label/swiss', breadcrumbName: $gettext('Swiss Blade') },
            ],
            component: HomeUseCaseSwiss,
            sidebarKey: '/home',
          },
        },
        {
          path: '/home/sdk',
          name: HOME_SDK_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeDetailView.vue'),
          meta: {
            title: $gettext('Blade SDK: Unleash Full-Spectrum Blockchain Capabilities'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/sdk', breadcrumbName: $gettext('Blade SDK') },
            ],
            component: HomeDetailSdk,
            sidebarKey: '/home',
          },
        },
        {
          path: '/home/sdk/karate',
          name: HOME_SDK_KARATE_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeUseCaseView.vue'),
          meta: {
            title: $gettext('Blade SDK: Karate Combat'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/sdk', breadcrumbName: $gettext('Blade SDK') },
              { path: '/home/sdk/karate', breadcrumbName: $gettext('Karate Combat') },
            ],
            component: HomeUseCaseKarate,
            sidebarKey: '/home',
          },
        },
        {
          path: '/home/token-studio',
          name: HOME_TOKEN_STUDIO_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeDetailView.vue'),
          meta: {
            title: $gettext('Token Studio: Power Your Digital Asset Strategy'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/token-studio', breadcrumbName: $gettext('Token Studio') },
            ],
            component: HomeDetailTokenStudio,
            sidebarKey: '/home',
          },
        },
        {
          path: '/home/multi-signature',
          name: HOME_MULTI_SIGNATURE_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeDetailView.vue'),
          meta: {
            title: $gettext('Multi-Signature Wallet: Empower Collaborative Security'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/multi-signature', breadcrumbName: $gettext('Multi-Signature Wallet') },
            ],
            component: HomeDetailMultiSig,
            sidebarKey: '/home',
          },
        },
        {
          path: '/home/loyalty',
          name: HOME_LOYALTY_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeDetailView.vue'),
          meta: {
            title: $gettext('Loyalty Card Drop: Next-Level Customer Engagement'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/loyalty', breadcrumbName: $gettext('Loyalty Card Drop') },
            ],
            component: HomeDetailLoyalty,
            sidebarKey: '/home',
          },
        },
        {
          path: '/home/loyalty/card',
          name: HOME_LOYALTY_CARD_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeUseCaseView.vue'),
          meta: {
            title: $gettext('Loyalty Card Drop: Blade Wallet Loyalty Card'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/loyalty', breadcrumbName: $gettext('Loyalty Card Drop') },
              { path: '/home/loyalty/card', breadcrumbName: $gettext('Blade Wallet Loyalty Card') },
            ],
            component: HomeUseCaseLoyalty,
            sidebarKey: '/home',
          },
        },
        {
          path: '/dashboard',
          name: DASHBOARD_SCREEN_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/DashboardView.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/campaigns',
          redirect: () => '/campaigns/overview',
        },
        {
          path: '/campaigns/:tab',
          name: CAMPAIGNS_SCREEN_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/DAppsView.vue'),
          meta: {
            sidebarKey: '/campaigns',
            requiresAuth: true,
          },
        },
        {
          path: '/sdk/:code',
          name: SDK_SCREEN_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/EditSdkView.vue'),
          meta: {
            sidebarKey: '/campaigns',
            guard: (currentUser: User) =>
              currentUser.capabilities.includes(Capability.UpdateCampaign) ||
              currentUser.roles.includes(UserRole.SUPPORT_ADMIN),
          },
        },
        {
          path: '/dapp/:code',
          name: DAPP_SCREEN_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/EditDappView.vue'),
          meta: {
            editDApp: true,
            sidebarKey: '/campaigns',
            guard: (currentUser: User) =>
              currentUser.capabilities.includes(Capability.UpdateCampaign) ||
              currentUser.roles.includes(UserRole.SUPPORT_ADMIN),
          },
        },
        {
          path: '/token-studio',
          name: TOKEN_STUDIO_SCREEN_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/TokenStudioView.vue'),
        },
        {
          path: '/multi-sig/:id',
          name: SAFE_SCREEN_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/SafeView.vue'),
          meta: {
            sidebarKey: '/multi-sig',
            requiresAuth: true,
          },
        },
        {
          path: '/multi-sig',
          name: SAFE_DASHBOARD_SCREEN_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/DashboardSafeView.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/billing',
          redirect: () => '/billing/overview',
        },
        {
          path: '/billing/:tab',
          name: BILLING_SCREEN_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/BillingView.vue'),
          meta: {
            sidebarKey: '/billing',
            requiresAuth: true,
          },
        },
        {
          path: '/pricing',
          name: PRICING_SCREEN_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/PricingView.vue'),
          meta: {
            sidebarKey: '/pricing',
          },
        },
        {
          path: '/settings',
          redirect: () => '/settings/profile',
        },
        {
          path: '/settings/:tab',
          name: SETTINGS_SCREEN_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/SettingsView.vue'),
          meta: {
            sidebarKey: '/settings',
            requiresAuth: true,
          },
        },
        {
          path: '/about',
          name: ABOUT_SCREEN_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/AboutView.vue'),
        },
      ],
    },
    {
      path: '/',
      component: UserLayout,
      children: [
        {
          path: '/login',
          name: LOGIN_SCREEN_ROUTE_NAME,
          component: LoginView,
        },
        {
          path: '/signup',
          name: SIGN_UP_ROUTE_NAME,
          component: LoginView,
        },
      ],
    },
    {
      path: '/',
      component: WizardLayout,
      children: [
        {
          name: SAFE_WIZARD_SCREEN_ROUTE_NAME,
          path: '/multi-sig/wizard',
          component: () => import('@/views/WizardLayout/SafeWizardView.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: TOKEN_STUDIO_WIZARD_SCREEN_ROUTE_NAME,
          path: '/token-studio/wizard',
          component: () => import('@/views/WizardLayout/TokenStudioWizardView.vue'),
        },
      ],
    },
    {
      path: '/',
      component: DAppWizardLayout,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          name: WIZARD_SCREEN_ROUTE_NAME,
          path: `/${WIZARD_ROUTE}/:code?`,
          component: () => import('@/views/DAppWizardView.vue'),
          redirect: ({ params }) => {
            const optionalParam = params.code ? `/${params.code}` : '';

            return `/${WIZARD_ROUTE}${optionalParam}/chain`;
          },
          meta: {
            wizard: true,
            guard: (currentUser: User) => currentUser.capabilities.includes(Capability.CreateCampaign),
          },
          children: [
            {
              name: DAPP_WIZARD_CHAIN,
              path: 'chain',
              component: () => import('@/components/DAppWizard/DAppWizardChainSelect.vue'),
              meta: {
                wizard: true,
              },
            },
            {
              name: DAPP_WIZARD_CAMPAIGN,
              path: 'campaign',
              component: () => import('@/components/DAppWizard/DAppWizardCampaign.vue'),
              meta: {
                wizard: true,
              },
            },
            {
              name: DAPP_WIZARD_CONNECT,
              path: 'connect',
              component: () => import('@/components/DAppWizard/DAppWizardWalletConnect.vue'),
              meta: {
                wizard: true,
              },
            },
            {
              name: DAPP_WIZARD_SETUP,
              path: 'setup',
              component: () => import('@/components/DAppWizard/DAppWizardSetup/DAppWizardSetup.vue'),
              meta: {
                wizard: true,
              },
              redirect: ({ params }) => {
                const optionalParam = params.code ? `/${params.code}` : '';

                return `/${WIZARD_ROUTE}${optionalParam}/setup/ft`;
              },
              children: [
                {
                  name: DAPP_WIZARD_SETUP_FT,
                  path: 'ft',
                  component: () => import('@/components/DAppWizard/DAppWizardSetup/DAppWizardSetupFt.vue'),
                  meta: {
                    wizard: true,
                  },
                },
                {
                  name: DAPP_WIZARD_SETUP_NFT,
                  path: 'nft',
                  component: () => import('@/components/DAppWizard/DAppWizardSetup/DAppWizardSetupNft.vue'),
                  meta: {
                    wizard: true,
                  },
                },
                {
                  name: DAPP_WIZARD_SETUP_MINT,
                  path: 'mint',
                  component: () => import('@/components/DAppWizard/DAppWizardSetup/DAppWizardSetupMint.vue'),
                  meta: {
                    wizard: true,
                  },
                },
              ],
            },
            {
              name: DAPP_WIZARD_MINT,
              path: 'mint',
              component: () => import('@/components/DAppWizard/DAppWizardMint/DAppWizardMint.vue'),
              redirect: ({ params }) => {
                const optionalParam = params.code ? `/${params.code}` : '';

                return `/${WIZARD_ROUTE}${optionalParam}/mint/configure`;
              },
              meta: {
                wizard: true,
              },
              children: [
                {
                  name: DAPP_WIZARD_MINT_CONFIGURE,
                  path: 'configure',
                  component: () => import('@/components/DAppWizard/DAppWizardMint/DAppWizardMintConfigure.vue'),
                  meta: {
                    wizard: true,
                  },
                },
                {
                  name: DAPP_WIZARD_MINT_PREVIEW,
                  path: 'preview',
                  component: () => import('@/components/DAppWizard/DAppWizardMint/DAppWizardMintPreview.vue'),
                  meta: {
                    wizard: true,
                  },
                },
              ],
            },
            {
              name: DAPP_WIZARD_ALLOWANCE,
              path: 'allowance',
              component: () => import('@/components/DAppWizard/DAppWizardAllowance/DAppWizardAllowance.vue'),
              meta: {
                wizard: true,
              },
              children: [
                {
                  name: DAPP_WIZARD_ALLOWANCE_FT,
                  path: 'ft',
                  component: () => import('@/components/DAppWizard/DAppWizardAllowance/DAppWizardAllowanceFt.vue'),
                  meta: {
                    wizard: true,
                  },
                },
                {
                  name: DAPP_WIZARD_ALLOWANCE_NFT,
                  path: 'nft',
                  component: () => import('@/components/DAppWizard/DAppWizardAllowance/DAppWizardAllowanceNft.vue'),
                  meta: {
                    wizard: true,
                  },
                },
              ],
            },
            {
              name: DAPP_WIZARD_SECURITY,
              path: 'security',
              component: () => import('@/components/DAppWizard/DAppWizardSecurity.vue'),
              meta: {
                wizard: true,
              },
            },
            {
              name: DAPP_WIZARD_REVIEW,
              path: 'preview',
              component: () => import('@/components/DAppWizard/DAppWizardReview/DAppWizardReview.vue'),
              meta: {
                wizard: true,
              },
            },
            {
              name: DAPP_WIZARD_SHARE,
              path: 'share',
              component: () => import('@/components/DAppWizard/DAppWizardShare.vue'),
              meta: {
                ignoreRouteChangeAlert: true,
              },
            },
          ],
        },
      ],
    },
    {
      path: '/',
      component: SafeAnonymousLayout,
      children: [
        {
          name: SAFE_SIGN_SCREEN_ROUTE_NAME,
          path: '/multi-sig/sign/:invite',
          component: () => import('@/views/SafeAnonymousLayout/SafeSignView.vue'),
        },
      ],
    },
    {
      path: '/',
      component: FullWidthLayout,
      children: [
        {
          path: '/disclaimer',
          name: DISCLAIMER_ROUTE_NAME,
          component: DisclaimerView,
        },
        {
          path: '/invite',
          name: USER_INVITE_ROUTE_NAME,
          component: UserInviteView,
        },
        {
          path: '/request-reset-password',
          name: REQUEST_RESET_PASSWORD_ROUTE_NAME,
          component: RequestResetPasswordView,
        },
        {
          path: '/reset-password',
          name: RESET_PASSWORD_ROUTE_NAME,
          component: ResetPasswordView,
        },
        {
          path: '/checkout/:sessionId',
          name: CHECKOUT_ROUTE_NAME,
          component: CheckoutView,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/logout',
      name: LOGOUT_SCREEN_ROUTE_NAME,
      component: LogoutView,
    },
    {
      path: '/drop/success',
      name: DROP_SUCCESS_SCREEN_ROUTE_NAME,
      component: DropSuccessView,
    },
    {
      path: '/drop/failure',
      name: DROP_FAILURE_SCREEN_ROUTE_NAME,
      component: DropFailureView,
    },
    {
      path: '/:pathMatch(.*)*',
      name: NOT_FOUND_ROUTE_NAME,
      component: NotFoundView,
    },
  ],
});

router.beforeEach(async (to, from) => {
  const userStore = useUserStore();
  const auth = getAuth();
  const { reloadUser, user } = userStore;
  const currentUser = user || (await reloadUser());

  if (getCookie(LAST_ACTIVITY_KEY) === null || !currentUser) {
    await auth.signOut();
    userStore.$reset();

    if (to.meta.requiresAuth || to.meta.guard) {
      return {
        path: '/login',
        query: {
          redirect: to.fullPath,
        },
      };
    }
  }

  if (currentUser) {
    (window as any).gtag('event', GAEvents.ROUTE_CHANGE, {
      path: to.path,
      orgName: currentUser.organization?.name,
      orgId: currentUser.organization?.id,
      firebaseId: currentUser.firebaseId,
    });
  }

  const guard = to.meta.guard as (user: User) => boolean;

  if (guard && !guard(currentUser)) {
    return from.name === LOGIN_SCREEN_ROUTE_NAME ? { path: '/' } : from;
  }
});

export default router;
