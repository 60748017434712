import { NetworkEnum } from '@/graphql/generatedTypesAndHooks';
import { Network } from '@/models';

export const normalizeNetworkEnumValues = (network: Network | NetworkEnum | string): NetworkEnum => {
  if (Object.values(NetworkEnum).includes(network as NetworkEnum)) {
    return network as NetworkEnum;
  }

  switch (network) {
    case Network.Testnet:
    case 'TESTNET':
      return NetworkEnum.HederaTestnet;
    case Network.Mainnet:
    case 'MAINNET':
      return NetworkEnum.HederaMainnet;
  }
};
