import gettext from '@/gettext';
import { getMinimalDecimals } from '@/utils/NumberHelpers';
import BigNumber from 'bignumber.js';

export const formatNumber = (num: BigNumber | number | string) => {
  return formatNumberWithDecimals(num, 2);
};

export const formatNumberWithMinDecimals = (num: BigNumber | number | string, decimals: number | string) => {
  return formatNumberWithDecimals(num, getMinimalDecimals(decimals));
};

export const formatNumberWithDecimals = (
  num: BigNumber | number | string,
  decimals: number | string,
  format?: BigNumber.Format
) => {
  const bigNumber = BigNumber(num);
  const formatOptions: BigNumber.Format = {
    decimalSeparator: getDecimalSeparator(),
    groupSeparator: getGroupSeparator(),
    ...format,
  };

  return bigNumber.toFormat(Number(decimals), BigNumber.ROUND_DOWN, formatOptions);
};

export const formatCurrency = (num: number | string, decimals: number | string, currency: string) => {
  const formattedNum = num ?? 0;

  const options: Intl.NumberFormatOptions = {
    currency,
    style: 'currency',
    minimumFractionDigits: Number(decimals),
    maximumFractionDigits: Number(decimals),
  };

  return new Intl.NumberFormat(gettext.current, options).format(Number(formattedNum)).toString();
};

export const getDecimalSeparator = () => {
  return (1.1).toLocaleString(gettext.current).substring(1, 2);
};

export const getGroupSeparator = () => {
  return (1000).toLocaleString(gettext.current).substring(1, 2);
};
