<script setup lang="ts">
import ServiceBlock from '@/components/Service/ServiceBlock.vue';
import ServiceChainList from '@/components/Service/ServiceChainList.vue';
import { Chain } from '@/models';
import { useSlots } from 'vue';

interface ChainSettings {
  chain: Chain;
  title: string;
  isComingSoon?: boolean;
}

interface Props {
  title?: string;
  subTitle?: string;
  chains?: ChainSettings[];
}

const props = defineProps<Props>();
const slots = useSlots();
</script>

<template>
  <ServiceBlock :title="props.title" class="serviceAbout">
    <div v-if="slots.content" class="serviceAbout__content">
      <slot name="content"></slot>
    </div>
    <div v-if="props?.chains?.length">
      <span>{{ $gettext('Chains') }}</span>
      <ServiceChainList :chains="props.chains" />
    </div>
    <a-row v-if="props.subTitle || slots.button" :gutter="[16, 16]" align="middle" class="serviceAbout__footer">
      <a-col flex="auto" v-if="props.subTitle" class="serviceAbout__subTitle">
        {{ props.subTitle }}
      </a-col>
      <a-col v-if="slots.button">
        <slot name="button"></slot>
      </a-col>
    </a-row>
  </ServiceBlock>
</template>

<style scoped lang="less">
.serviceAbout {
  &__subTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  &__footer {
    width: 100%;
  }

  &__content {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}
</style>
