import { SaucerSwapClientFactory } from '@/client';
import { SAUCER_SWAP_HBAR_TOKEN_ID } from '@/config';
import type { NetworkEnum } from '@/graphql/generatedTypesAndHooks';
import { ChainMap } from '@/models';
import type { RateData } from '@/services/RateService';

type APIRateData = {
  decimals: number;
  icon: string | null;
  id: string;
  name: string;
  price: string;
  priceUsd: number;
  symbol: string;
  dueDiligenceComplete: boolean;
  isFeeOnTransferToken: boolean;
  description: string | null;
  website: string | null;
  twitterHandle: string | null;
  timestampSecondsLastListingChange: number;
};

export class SaucerSwapService {
  public static async getRates(network: NetworkEnum): Promise<RateData[]> {
    return SaucerSwapClientFactory.getClient(network)
      .get<APIRateData[]>('tokens')
      .then((res) =>
        res.data.map((rate) => ({
          tokenId: rate.id === SAUCER_SWAP_HBAR_TOKEN_ID[network] ? ChainMap[network].nativeTokenId : rate.id,
          hbarPrice: rate.price,
          usdPrice: rate.priceUsd,
          decimals: rate.decimals,
        }))
      );
  }
}
