import { BldConsoleError, ErrorLogLvl, TransactionError, WalletError } from '@/models';
import { MonitoringService } from '@/services/MonitoringService';
import { AxiosError } from 'axios';

export class ExceptionHandler {
  public static handleError(error: any) {
    const wrappedError = ExceptionHandler.wrapError(error);

    if (wrappedError.logLvl !== ErrorLogLvl.NONE) {
      ExceptionHandler.logError(wrappedError);
    }

    switch (wrappedError.constructor) {
      case WalletError:
      case TransactionError:
      default:
        MonitoringService.reportError(error.message);
    }
  }

  private static wrapError(error: any): BldConsoleError {
    if (error instanceof BldConsoleError) {
      return error;
    } else if (error instanceof AxiosError) {
      const errorMsg = (error as any).response?.data?.message;
      return new BldConsoleError(error, errorMsg);
    } else if (error?.transactionId) {
      return new TransactionError(error);
    } else {
      return new BldConsoleError(error);
    }
  }

  private static logError(error: BldConsoleError): void {
    console[error.logLvl](error.message);

    if (error.originalError) {
      console[error.logLvl](error.originalError);
    }

    if (error.originalError?.error) {
      console[error.logLvl](error.originalError?.error);
    }
  }
}
