import gettext from '@/gettext';
import { Chain, type Costs } from '@/models';

const { $gettext } = gettext;

export const tokenStudioCosts: Costs = {
  [Chain.HEDERA]: [
    {
      name: $gettext('Token creation'),
      cost: '0.5',
    },
    {
      name: $gettext('Token mint'),
      cost: '0.5',
    },
    {
      name: $gettext('Blade Console services'),
      cost: '0.0',
      label: { name: $gettext('Free'), color: 'blue' },
    },
  ],
};
