<script setup lang="ts">
import { useRouter } from 'vue-router';
import { HOME_SCREEN_ROUTE_NAME } from '@/router';

const router = useRouter();
</script>

<template>
  <div class="disclaimerView">
    <a-card class="disclaimerView__card">
      <h3 class="disclaimerView__title">{{ $gettext('Disclaimer') }}</h3>
      <p class="disclaimerView__text">
        {{
          $gettext(
            `Nothing on this website is intended to be professional advice, including without limitation, financial, investment, legal or tax advice. Blade Labs Holdings Pte Ltd., together with Blade Labs Inc., Blade Foundation Ltd. and any affiliates or subsidiaries (collectively, “Blade,” “we,” “us,” or “our”) is not responsible for your use of or reliance on any information in this website.`
          )
        }}
      </p>
      <p class="disclaimerView__text">
        {{
          $gettext(
            `Any of the trademarks, service marks, collective marks, design rights or similar rights used or cited in this website are the property of their respective owners. Their use here does not imply endorsement by or affiliation with the holders of such rights, and as such no rights are granted to use  any otherwise protected materials, except as otherwise explicitly stated.`
          )
        }}
      </p>
      <p class="disclaimerView__text">
        {{
          $gettext(
            `The Blade Console (including but not limited to all of its features, such as Blade Token Drops, Blade Multi-Sig, and Blade Analytics) is still in the testing phase and is provided on an “AS IS” and “AS AVAILABLE” basis, without any representations or warranties of any kind. Blade Labs will not be liable for any loss, whether such loss is direct, indirect, special or consequential, suffered by any party as a result of their use of the Console. Use of the Blade Console is done at entirely your own risk.`
          )
        }}
      </p>
      <p class="disclaimerView__text">
        {{
          $gettext(
            `Transactions made on distributed ledger technology (such as Hedera Hashgraph, Ethereum, etc) are irreversible and Blade does not have the ability to reverse or recall any transaction once initiated. You bear all responsibility for any losses that might be incurred as a result of sending digital assets to an incorrect or unintended wallet or account ID.`
          )
        }}
      </p>
      <p class="disclaimerView__text">
        {{
          $gettext(
            `You are responsible for complying with all applicable laws. Blade is not responsible for determining whether or which laws may apply to your use of the Console or transactions made on a blockchain, including tax laws.`
          )
        }}
      </p>
      <p class="disclaimerView__text">
        {{
          $gettext(
            `The regulatory status of many tokens (fungible and non-fungible) and blockchain technology is unclear or unsettled in many jurisdictions. It is difficult to predict how or whether regulatory agencies may apply existing regulation with respect to NFTs, blockchain technology, and its applications. Accordingly, it is not possible to determine how different blockchain transactions would be recognized under applicable law by a court or regulator at the U.S. state, U.S. federal, or international level.`
          )
        }}
      </p>
    </a-card>

    <div class="disclaimerView__close">
      <a-button type="text" @click="router.push({ name: HOME_SCREEN_ROUTE_NAME })">{{ $gettext('Close') }}</a-button>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import '@/assets/variables';

.disclaimerView {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - @header-height);
  width: 100%;
  height: 100%;

  &__title {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }

  &__card {
    max-width: 920px;
  }

  &__text {
    color: rgba(0, 0, 0, 0.45);
  }

  &__close {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
    align-self: flex-end;
    margin-top: auto;
  }
}
</style>
