import { HbarTokenId, type TokenAmount } from '@/models';
import { AccountAllowanceApproveTransaction, Hbar, HbarUnit, TransactionId } from '@hashgraph/sdk';
import Long from 'long';

export class HederaTransactionService {
  static createTokensAllowanceTransaction(
    allowanceAmounts: TokenAmount[],
    ownerAccountId: string,
    spenderAccountId: string
  ) {
    const tokens = allowanceAmounts.filter((ta) => ta.token.tokenId !== HbarTokenId);
    const hbar = allowanceAmounts.find((ta) => ta.token.tokenId === HbarTokenId);
    const transaction = new AccountAllowanceApproveTransaction();

    if (hbar) {
      transaction.approveHbarAllowance(
        ownerAccountId,
        spenderAccountId,
        Hbar.fromString(hbar.amount, HbarUnit.Tinybar)
      );
    }

    tokens.forEach((ta) => {
      transaction.approveTokenAllowance(ta.token.tokenId, ownerAccountId, spenderAccountId, Long.fromString(ta.amount));
    });

    transaction.setTransactionMemo('Blade Console allowance transaction');
    transaction.setTransactionId(TransactionId.generate(ownerAccountId));

    return transaction;
  }

  static createNftAllowanceTransactions(tokenIds: string[], ownerAccountId: string, spenderAccountId: string) {
    const transaction = new AccountAllowanceApproveTransaction();

    tokenIds.forEach((tokenId) =>
      transaction.approveTokenNftAllowanceAllSerials(tokenId, ownerAccountId, spenderAccountId)
    );

    transaction.setTransactionMemo('Blade Console allowance transaction');
    transaction.setTransactionId(TransactionId.generate(ownerAccountId));

    return transaction;
  }
}
