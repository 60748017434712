import { apolloClient } from '@/ApolloClient';
import { APP_VERSION, BUILD_ENV, SENTRY_DSN } from '@/config';
import * as Sentry from '@sentry/vue';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import 'reflect-metadata';
import 'setimmediate';
import { createApp, h, provide } from 'vue';

import 'ant-design-vue/dist/reset.css';
import App from './App.vue';
import './assets/main.less';
import router from './router';

import { firebaseApp } from '@/firebase';
import { VueFire, VueFireAuth } from 'vuefire';
import gettext from './gettext';

window.global ||= window;

if (!(window as any).gtag) {
  (window as any).gtag = () => {};
}

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },

  render: () => h(App),
});

if (BUILD_ENV === 'prod' || BUILD_ENV === 'ci') {
  Sentry.init({
    app,
    dsn: SENTRY_DSN,
    environment: BUILD_ENV,
    release: APP_VERSION,
    trackComponents: true,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

app.use(VueFire, {
  firebaseApp,
  modules: [VueFireAuth()],
});
app.use(pinia);
app.use(gettext);
app.use(router);

app.mount('#app');
