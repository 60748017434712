<script setup lang="ts">
import ServiceBlock from '@/components/Service/ServiceBlock.vue';
import type { Features } from '@/models';

interface Props {
  title: string;
  features: Features[];
}

const props = defineProps<Props>();

const filteredFeatures = props.features.filter(
  (feature) => feature.detailed === true || feature.detailed === undefined
);
</script>

<template>
  <ServiceBlock :title="props.title" class="serviceFeatures">
    <div class="serviceFeatures__content" v-for="feature in filteredFeatures" :key="feature.name">
      <div class="serviceFeatures__nameContainer">
        <img class="serviceFeatures__icon" v-if="feature.icon" :src="feature.icon" :alt="feature.name" />
        <span class="serviceFeatures__name">
          {{ feature.name }}
          <template v-if="feature.isComingSoon">({{ $gettext('Coming soon') }})</template>
        </span>
      </div>
      <div class="serviceFeatures__description">{{ feature.description }}</div>
    </div>
  </ServiceBlock>
</template>

<style scoped lang="less">
.serviceFeatures {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }

  &__name {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &__nameContainer {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
</style>
