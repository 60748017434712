import { AbstractWalletConnectService } from '@/services/networks/AbstractWalletConnectService';
import type { FeeBumpTransaction, Transaction } from '@stellar/stellar-sdk';
import { TransactionBuilder } from '@stellar/stellar-sdk';

export class StellarWalletConnectService extends AbstractWalletConnectService {
  async signAndSubmitXDR(transaction: Transaction | FeeBumpTransaction): Promise<'success' | 'pending'> {
    const { status } = await this.requestWrapper(
      this.client.request<{ status: 'success' | 'pending' }>({
        topic: this.session.topic,
        chainId: this.chainId,
        request: {
          method: 'stellar_signAndSubmitXDR',
          params: {
            xdr: transaction.toXDR(),
          },
        },
      })
    );

    return status;
  }

  async signXDR(transaction: Transaction | FeeBumpTransaction): Promise<Transaction | FeeBumpTransaction> {
    const { signedXDR } = await this.requestWrapper(
      this.client.request<{ signedXDR: string }>({
        topic: this.session.topic,
        chainId: this.chainId,
        request: {
          method: 'stellar_signXDR',
          params: {
            xdr: transaction.toXDR(),
          },
        },
      })
    );

    return TransactionBuilder.fromXDR(signedXDR, transaction.networkPassphrase);
  }
}
