import gettext from '@/gettext';
import type { Features } from '@/models';

const { $gettext } = gettext;

export const loyaltyFeatures: Features[] = [
  {
    name: $gettext('Craft Tiered NFT Rewards'),
    description: $gettext(
      'Design loyalty levels with unique benefits and monitor progress via blockchain for precise reward management.'
    ),
    isComingSoon: false,
    detailed: true,
    preview: true,
  },
  {
    name: $gettext('Dynamic Updates'),
    description: $gettext(
      'Modify loyalty conditions in real-time with our Hedera Interface Protocol, ensuring your loyalty program evolves with market needs.'
    ),
    isComingSoon: false,
    detailed: true,
    preview: true,
  },
  {
    name: $gettext('Automated Gating'),
    description: $gettext('Soon, automatically adjust access based on user activity and secured blockchain criteria.'),
    isComingSoon: false,
    detailed: true,
    preview: true,
  },
  {
    name: $gettext('Streamlined Management'),
    description: $gettext('Manage your campaign with intuitive NFT distribution and oversight tools.'),
    isComingSoon: false,
    detailed: true,
    preview: true,
  },
  {
    name: $gettext('No-Fee Transactions'),
    description: $gettext('Facilitate smooth customer experiences by covering gas fees during NFT claims.'),
    isComingSoon: false,
    detailed: true,
    preview: true,
  },
  {
    name: $gettext('Direct Sales'),
    description: $gettext(
      'Easily set prices for NFT drops and streamline purchases for a frictionless customer journey.'
    ),
    isComingSoon: false,
    detailed: true,
    preview: true,
  },
  {
    name: $gettext('Paid drop'),
    isComingSoon: true,
    detailed: false,
    preview: true,
  },
];
