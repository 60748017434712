export class NftInfo {
  constructor(
    public tokenId: string,
    public name: string,
    public metadata: string,
    public serial: number,
    public accountId: string,
    public spenderId: string,
    public delegatingSpender: string,
    public createdTimestamp: string,
    public modifiedTimestamp: string,
    public deleted: boolean
  ) {}
}
