import { NetworkEnum } from '@/graphql/generatedTypesAndHooks';
import { Hbar, TokenType, type Balance } from '@/models';
import { ApiService } from '@/services/ApiService';
import { useRateStore, useTokenStore } from '@/stores';
import BigNumber from 'bignumber.js';
import { AbstractBalanceService } from '../AbstractBalanceService';

class HederaBalance extends AbstractBalanceService {
  chains: NetworkEnum[] = [NetworkEnum.HederaTestnet, NetworkEnum.HederaMainnet];

  public async fetchNativeBalance(accountId: string, network: NetworkEnum): Promise<Balance> {
    if (!accountId) {
      throw Error('No Account for getting Hbar balance provided');
    }

    const token = new Hbar(network);
    const accountInfo = await ApiService.getAccountInfo(accountId, network);
    const balance = BigNumber(accountInfo.balance.balance || 0);
    const rate = await useRateStore().getUSDRate(token.tokenId, network);
    const fiatBalance = balance.multipliedBy(rate).shiftedBy(-Number(token.decimals));

    return {
      balance: balance.toString(),
      network,
      accAddress: accountId,
      tokenId: token.tokenId,
      token: token,
      symbol: token.symbol,
      type: TokenType.Fungible,
      autoAssociated: false,
      frozen: false,
      kycStatus: 'NOT_APPLICABLE',
      fiatBalance: fiatBalance.toString(),
      serial: '',
    };
  }

  public async fetchTokenBalances(accountId: string, network: NetworkEnum, tokenId?: string): Promise<Balance[]> {
    if (!accountId) {
      throw Error('No Account for getting token balances provided');
    }

    const tokens = tokenId
      ? (await ApiService.getAccountTokens(accountId, network, null, tokenId)).tokens
      : await ApiService.getAllAccountTokens(accountId, network);

    return Promise.all(
      tokens.map(async (accountToken) => {
        const token = await useTokenStore().getTokenInfo(accountToken.token_id, network);
        const rate = await useRateStore().getUSDRate(accountToken.token_id, network);
        const balance = BigNumber(accountToken.balance || 0);
        const fiatBalance = balance.multipliedBy(rate).shiftedBy(-Number(token.decimals));

        return {
          balance: balance.toString(),
          network,
          accAddress: accountId,
          tokenId: token.tokenId,
          token: token,
          symbol: token.symbol,
          type: TokenType.Fungible,
          autoAssociated: false,
          frozen: accountToken.freeze_status === 'FROZEN',
          kycStatus: 'NOT_APPLICABLE',
          fiatBalance: fiatBalance.toString(),
          serial: '',
        };
      })
    );
  }
}

export const HederaBalanceService = new HederaBalance();
