import gettext from '@/gettext';
import { Chain, type Costs } from '@/models';

const { $gettext } = gettext;

export const combinedDropCosts: Costs = {
  [Chain.HEDERA]: [
    {
      name: $gettext('NFT mint'),
      cost: '0.05',
    },
    {
      name: $gettext('Token association/account creation'),
      cost: '0.05',
    },
    {
      name: $gettext('Blade Console services'),
      cost: '0.2',
      label: {
        name: $gettext('Ninja Plan'),
        color: 'default',
      },
    },
  ],
};
