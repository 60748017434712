<script setup lang="ts">
import ChainCard from '@/components/ChainCard.vue';
import { Chain } from '@/models';

interface ChainSettings {
  title: string;
  chain: Chain;
  isComingSoon?: boolean;
}

interface Props {
  value?: Chain;
  chains: ChainSettings[];
}

const props = defineProps<Props>();
const emit = defineEmits<{ (e: 'update:value', value: Chain): void }>();

const onClick = (item: ChainSettings) => !item.isComingSoon && emit('update:value', item.chain);
</script>

<template>
  <div class="serviceChainList">
    <a-row class="serviceChainList__chainList" :gutter="[8, 16]">
      <a-col v-for="item in props.chains" :key="item.chain">
        <div
          class="serviceChainList__chain"
          :class="{
            'serviceChainList__chain--selected': props.value === item.chain,
            'serviceChainList__chain--disabled': item.isComingSoon,
          }"
          @click="onClick(item)"
        >
          <ChainCard class="serviceChainList__chainCard" :chain="item.chain" />
          {{ item.title }}
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<style lang="less" scoped>
.serviceChainList {
  &__chainList {
    margin: 8px 0;
  }

  &__chain {
    display: inline-flex;
    align-items: center;
    padding: 4px 7px 4px 4px;
    border-radius: 99px;
    border: 1px solid #d9d9d9;
    background: #fff;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);

    &:not(&--disabled) {
      cursor: pointer;
    }

    &--selected {
      border-color: #8d7feb;
    }

    &--disabled {
      color: rgba(0, 0, 0, 0.85);
      opacity: 0.5;
    }
  }

  &__chainCard {
    margin-right: 4px;
  }
}
</style>
