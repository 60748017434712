<script setup lang="ts">
import type { SignUpFormModel } from './SignUpFormModel';
import { useSubmitForm, useValidateForm } from '@/functions';
import { requiredRule, spacesRule } from '@/validation';
import { reactive, ref, watch } from 'vue';
import { useGettext } from 'vue3-gettext';
import type { FormInstance } from 'ant-design-vue/es/form';
import Schema, { type Rule, type Rules } from 'async-validator';

interface Props {
  value: SignUpFormModel;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'update:value', value: SignUpFormModel): void;
  (e: 'enter'): void;
}>();
const formRef = ref<FormInstance>();
const initialModel: SignUpFormModel = {
  organizationName: '',
  email: '',
};
const formModel = reactive<SignUpFormModel>({ ...initialModel, ...props.value });
const { $gettext } = useGettext();

const descriptor: Rules = {
  email: [requiredRule, { type: 'email', message: $gettext('Invalid email address.') }],
  organizationName: [
    spacesRule,
    requiredRule,
    { type: 'string', min: 3, message: $gettext('Minimum 3 characters.') },
    { type: 'string', max: 100, message: $gettext('Maximum 100 characters.') },
  ],
} as Record<keyof SignUpFormModel, Rule>;
const validator = new Schema(descriptor);
const validateForm = useValidateForm(formModel, reactive(validator.rules));
const onFinish = (v: SignUpFormModel) => emit('update:value', v);
const submitForm = useSubmitForm(formRef, onFinish);

watch(
  formModel,
  (v) => {
    emit('update:value', v);
  },
  { deep: true }
);

defineExpose({ submitForm, validateForm });
</script>

<template>
  <a-form
    hideRequiredMark
    ref="formRef"
    layout="vertical"
    validateTrigger="blur"
    :model="formModel"
    :rules="validator.rules"
  >
    <a-form-item name="email">
      <a-input
        @keyup.enter="emit('enter')"
        v-model:value="formModel.email"
        type="text"
        :placeholder="$gettext('Email')"
        autocomplete="off"
      />
    </a-form-item>
    <a-form-item name="organizationName">
      <a-input
        @keyup.enter="emit('enter')"
        v-model:value="formModel.organizationName"
        type="text"
        :placeholder="$gettext('Organization Name')"
        autocomplete="off"
      />
    </a-form-item>
  </a-form>
</template>
