<script setup lang="ts">
import { multiSigFeatures } from '@/components/Home/features';
import ServiceAbout from '@/components/Service/ServiceAbout.vue';
import ServiceFeatures from '@/components/Service/ServiceFeatures.vue';
import ServiceSupport from '@/components/Service/ServiceSupport.vue';
import ServiceVideo from '@/components/Service/ServiceVideo.vue';
import { SAFE_WIZARD_SCREEN_ROUTE_NAME } from '@/router';
import { useRouter } from 'vue-router';

const router = useRouter();

const open = async () => {
  await router.push({ name: SAFE_WIZARD_SCREEN_ROUTE_NAME });
};
</script>

<template>
  <div class="homeDetailMultiSig">
    <a-row :gutter="[16, 16]">
      <a-col :xs="24" :md="12" :lg="12" :xl="12">
        <ServiceAbout
          :title="
            $gettext(
              `Introducing Blade Console's Multi-Signature Wallet, engineered for optimal security and ` +
                `collaborative governance. This service ensures that transactions proceed only after securing all ` +
                `necessary approvals, enhancing both safety and compliance. It is perfect for organizations that ` +
                `require collective decision-making.`
            )
          "
          :subTitle="$gettext(`Ready to start? This service is free of charge.`)"
        >
          <template #content>
            <div class="homeDetailMultiSig__about">
              {{ $gettext(`Why Choose Blade Console's Multi-Sig Wallet?`) }}
            </div>

            <div class="homeDetailMultiSig__featureBlock">
              <div class="homeDetailMultiSig__featureTitle"></div>
              {{ $gettext('Enhanced Security') }}
              <div class="homeDetailMultiSig__featureDescription">
                {{
                  $gettext(
                    'Transactions are executed only after receiving all designated approvals, preventing unauthorized access and actions.'
                  )
                }}
              </div>
            </div>

            <div class="homeDetailMultiSig__featureBlock">
              <div class="homeDetailMultiSig__featureTitle"></div>
              {{ $gettext('Streamlined Collaboration') }}
              <div class="homeDetailMultiSig__featureDescription">
                {{
                  $gettext(
                    'Facilitates secure and efficient multi-party transactions, ensuring transparent and democratic decision-making processes.'
                  )
                }}
              </div>
            </div>

            <div class="homeDetailMultiSig__featureBlock">
              <div class="homeDetailMultiSig__featureTitle"></div>
              {{ $gettext('Comprehensive Support') }}
              <div class="homeDetailMultiSig__featureDescription">
                {{
                  $gettext(
                    `Includes dedicated backend services to ensure smooth operation and scalability, backed by Blade's trusted SDK.`
                  )
                }}
              </div>
            </div>
          </template>
          <template #button>
            <a-button type="primary" size="large" @click="open()">
              {{ $gettext('Create Multi-Signature Wallet') }}
            </a-button>
          </template>
        </ServiceAbout>
        <ServiceFeatures :title="$gettext('Service features')" :features="multiSigFeatures" />
      </a-col>
      <a-col :xs="24" :md="12" :lg="12" :xl="12">
        <ServiceVideo videoId="2Hy1TrbqC2w" />
        <ServiceSupport />
      </a-col>
    </a-row>
  </div>
</template>

<style lang="less">
.homeDetailMultiSig {
  padding: 16px;

  &__featureBlock {
    margin-top: 16px;
  }

  &__featureTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  &__featureDescription {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &__about {
    color: rgba(0, 0, 0, 0.45);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
</style>
