import { StellarClientFactory } from '@/client';
import { NetworkEnum } from '@/graphql/generatedTypesAndHooks';
import { Balance, ChainMap, Token, TokenType } from '@/models';
import { Xlm } from '@/models/nativeTokens';
import BigNumber from 'bignumber.js';
import { AbstractBalanceService } from '../AbstractBalanceService';

class StellarBalance extends AbstractBalanceService {
  chains: NetworkEnum[] = [NetworkEnum.StellarMainnet, NetworkEnum.StellarTestnet];

  async fetchNativeBalance(accountId: string, network: NetworkEnum): Promise<Balance> {
    const xlm = new Xlm(network);
    const client = StellarClientFactory.getClient(network);
    const account = await client.loadAccount(accountId);
    const nativeBalance = account.balances.find((balance) => balance.asset_type === 'native');

    return {
      balance: BigNumber(nativeBalance.balance || 0)
        .shiftedBy(Number(xlm.decimals))
        .toString(),
      network,
      accAddress: accountId,
      tokenId: xlm.tokenId,
      token: xlm,
      symbol: xlm.symbol,
      type: TokenType.Fungible,
      autoAssociated: false,
      frozen: false,
      kycStatus: 'NOT_APPLICABLE',
      fiatBalance: undefined,
      serial: '',
    };
  }

  async fetchTokenBalances(accountId: string, network: NetworkEnum, tokenId?: string): Promise<Balance[]> {
    const xlm = new Xlm(network);
    const client = StellarClientFactory.getClient(network);
    const { balances } = await client.loadAccount(accountId);

    const filteredBalances = tokenId
      ? balances.filter((b) => ((b as any)?.asset_issuer || ChainMap[network].nativeTokenId) === tokenId)
      : balances;

    return Promise.all(
      filteredBalances
        .sort((a) => (a.asset_type === 'native' ? -1 : 1))
        .filter(
          (b) =>
            b.asset_type === 'native' || b.asset_type === 'credit_alphanum4' || b.asset_type === 'credit_alphanum12'
        )
        .map(async (token) => {
          const balance = BigNumber(token.balance || 0);
          if (token.asset_type === 'credit_alphanum4' || token.asset_type === 'credit_alphanum12') {
            const decimals = token?.buying_liabilities?.split('.')[1]?.length || 0;

            return {
              tokenId: token.asset_issuer,
              token: new Token(
                token.asset_issuer,
                network,
                token.asset_code,
                token.asset_code,
                decimals.toString(),
                TokenType.Fungible
              ),
              symbol: token.asset_code,
              accAddress: accountId,
              network,
              balance: balance.shiftedBy(Number(decimals)).toString(),
              autoAssociated: false,
              frozen: !token.is_authorized_to_maintain_liabilities,
              kycStatus: token.is_authorized ? 'GRANTED' : 'REVOKED',
              type: TokenType.Fungible,
              serial: '',
              fiatBalance: undefined,
            };
          } else {
            return {
              balance: balance.shiftedBy(Number(xlm.decimals)).toString(),
              network,
              accAddress: accountId,
              tokenId: ChainMap[network].nativeTokenId,
              token: new Xlm(network),
              symbol: ChainMap[network].currency,
              type: TokenType.Fungible,
              autoAssociated: false,
              frozen: false,
              kycStatus: 'NOT_APPLICABLE',
              fiatBalance: undefined,
              serial: '',
            };
          }
        })
    );
  }
}

export const StellarBalanceService = new StellarBalance();
