import { NetworkEnum } from '@/graphql/generatedTypesAndHooks';
import { normalizeNetworkEnumValues } from '@/utils';
import { Client } from '@hashgraph/sdk';
import { defineStore } from 'pinia';

export const useHederaClientStore = defineStore('hederaClientStore', () => {
  const getClient = async (network: NetworkEnum): Promise<Client> => {
    switch (normalizeNetworkEnumValues(network)) {
      case NetworkEnum.HederaMainnet:
        return Client.forMainnet();
      case NetworkEnum.HederaTestnet:
        return Client.forTestnet();
      case NetworkEnum.HederaPreviewnet:
        return Client.forPreviewnet();
      default:
        throw new Error(`Unsupported network: ${network}`);
    }
  };

  return {
    getClient,
  };
});
