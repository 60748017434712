import type { Ref } from 'vue';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

export function useNavigationHelper(): {
  path: Ref<string>;
  basePath: Ref<string>;
  matchedPaths: Ref<string[]>;
} {
  const route = useRoute();
  const path = ref<string>('');
  const basePath = ref<string>('');
  const matchedPaths = ref<string[]>([]);

  watch(
    route,
    (v) => {
      path.value = v.path;
      basePath.value = v.matched.length > 2 ? v.matched[v.matched.length - 2].path : '/';
      matchedPaths.value = v.matched.map((v) => (v?.meta?.sidebarKey as string) || v.path);
    },
    { immediate: true }
  );

  return { path, basePath, matchedPaths };
}
