<script setup lang="ts"></script>

<template>
  <a-layout-header class="header">
    <a-row class="header__container" align="middle" :wrap="false">
      <a-col v-if="$slots.logo">
        <slot name="logo"></slot>
      </a-col>
      <a-col flex="auto" id="pageHeader"></a-col>
      <a-col v-if="$slots.user" class="header__item">
        <slot name="user"></slot>
      </a-col>
    </a-row>
  </a-layout-header>
</template>

<style lang="less" scoped>
.header {
  &__container {
    height: 100%;
  }

  &__item {
    padding: 0 16px;

    &:last-child {
      padding-right: 0;
    }
  }

  &__divider {
    height: 100%;

    .ant-divider-vertical {
      height: 100%;
      margin: 0;
    }
  }
}
</style>
