import gettext from '@/gettext';
import type { Features } from '@/models';
import BurnIcon from '/public/icons/burn.svg';
import DesignIcon from '/public/icons/design.svg';
import FreezeIcon from '/public/icons/freeze.svg';
import KycIcon from '/public/icons/kyc.svg';
import PauseIcon from '/public/icons/pause.svg';
import ProofIcon from '/public/icons/proof.svg';
import RolesIcon from '/public/icons/roles.svg';
import WipeIcon from '/public/icons/wipe.svg';

const { $gettext } = gettext;

export const tokenStudioFeatures: Features[] = [
  {
    name: $gettext('Design tokens'),
    description: $gettext(
      `Craft tokens with precision, selecting the attributes and functionality that resonate with your project's vision.`
    ),
    isComingSoon: false,
    detailed: true,
    preview: true,
    icon: DesignIcon,
  },
  {
    name: $gettext('Proof-of-Reserve'),
    description: $gettext(`Assure stakeholders of your token's value with verifiable asset backing.`),
    isComingSoon: false,
    detailed: true,
    preview: true,
    icon: ProofIcon,
  },
  {
    name: $gettext('Burn'),
    description: $gettext(
      'Permanently remove tokens from circulation to address economic models or reduce the total supply for increased rarity.'
    ),
    isComingSoon: false,
    detailed: true,
    preview: true,
    icon: BurnIcon,
  },
  {
    name: $gettext('Wipe'),
    description: $gettext('Adjust token supply dynamically, enhancing economic strategies.'),
    isComingSoon: false,
    detailed: true,
    preview: true,
    icon: WipeIcon,
  },
  {
    name: $gettext('Freeze'),
    description: $gettext('Implement temporary stops on token transactions for security or updates.'),
    isComingSoon: false,
    detailed: true,
    preview: true,
    icon: FreezeIcon,
  },
  {
    name: $gettext('Pause'),
    description: $gettext(
      'Temporarily suspend token transactions without affecting the overall token supply, providing operational discretion.'
    ),
    isComingSoon: false,
    detailed: true,
    preview: true,
    icon: PauseIcon,
  },
  {
    name: $gettext('Roles'),
    description: $gettext('Establish governance structures within your token ecosystem.'),
    isComingSoon: false,
    detailed: true,
    preview: true,
    icon: RolesIcon,
  },
  {
    name: $gettext('KYC'),
    description: $gettext('Integrate necessary verification processes for compliance and security.'),
    isComingSoon: false,
    detailed: true,
    preview: true,
    icon: KycIcon,
  },
];
