import gettext from '@/gettext';
import type { Features } from '@/models';

const { $gettext } = gettext;

export const multiSigFeatures: Features[] = [
  {
    name: $gettext('Robust Multi-Signature Protocols'),
    description: $gettext(
      `Customize authentication rules to fit your organizational workflow and security requirements.`
    ),
    isComingSoon: false,
    detailed: true,
    preview: true,
  },
  {
    name: $gettext('Multi-Recipient Transactions'),
    description: $gettext(`Simplify transactions across multiple stakeholders, enhancing efficiency and security.`),
    isComingSoon: false,
    detailed: true,
    preview: true,
  },
  {
    name: $gettext('Real-Time Email Notifications'),
    description: $gettext(
      'Keep all parties informed with instant updates on transaction status and required approvals.'
    ),
    isComingSoon: false,
    detailed: true,
    preview: true,
  },
];
