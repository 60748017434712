import translations from '@/language/translations.json';
import { createGettext } from 'vue3-gettext';
import locales from './locales.json';

export default createGettext({
  availableLanguages: locales,
  setGlobalProperties: true,
  defaultLanguage: 'en',
  globalProperties: {
    language: ['$language'],
    gettext: ['$gettext'],
    pgettext: ['$pgettext'],
    ngettext: ['$ngettext'],
    npgettext: ['$npgettext'],
  },
  translations: translations,
  provideDirective: true,
  provideComponent: true,
});
