import { WalletException } from '@/exception';
import gettext from '@/gettext';
import { ErrorCode } from '@/graphql/generatedTypesAndHooks';
import { StablecoinSDKService } from '@/services/StablecoinSDKService';
import { ApolloError } from '@apollo/client/errors';
import { ReceiptStatusError } from '@hashgraph/sdk';
import { notification } from 'ant-design-vue';
import type { NotificationArgsProps } from 'ant-design-vue/lib/notification';

const { $gettext } = gettext;

export const errorMessages: Record<ErrorCode, (p: { [key: string]: string }) => string> = {
  [ErrorCode.WalletNotSafeMember]: () => $gettext('Wallet is not a member of this safe'),
  [ErrorCode.SignatureConflict]: () => $gettext('Wallet already signed this transaction'),
  [ErrorCode.InvalidSignature]: () => $gettext('Invalid signature'),
  [ErrorCode.GetAccountInfoFailed]: () => $gettext('Failed to get account info'),
  [ErrorCode.StrategyNotFound]: (p) => $gettext('Strategy not found for transaction with type %{transactionType}', p),
  [ErrorCode.TransactionAlreadySigned]: () => $gettext('You already signed this transaction'),
  [ErrorCode.SignatureOutdated]: () => $gettext('The original signature is out of date, please try again'),
  [ErrorCode.SignatureError]: () => $gettext('Transaction signature error'),
  [ErrorCode.SignatoryNotSafeMember]: () => $gettext('Signatory is not a member of the safe'),
  [ErrorCode.WalletAlreadyCreated]: () => $gettext('Wallet already created!'),
  [ErrorCode.CreateTransactionNotFound]: (p) => $gettext('Create account transaction not found for safe %{safeId}', p),
  [ErrorCode.NotEnoughSignatures]: (p) => $gettext('Not enough signatures for safe %{safeId}', p),
  [ErrorCode.SignatureNotSupported]: () =>
    $gettext('Signature for account creation transaction is not supported, use Handshake'),
  [ErrorCode.RecipientsRequired]: () => $gettext('Recipients are required!'),
  [ErrorCode.RecipientsLimit]: () => $gettext('Only 10 recipients are allowed!'),
  [ErrorCode.SafeNotCreated]: (p) => $gettext('Safe %{safeId} not created', p),
  [ErrorCode.TransactionNotFound]: () => $gettext('Transaction not found'),
  [ErrorCode.SafeNotFound]: () => $gettext('Safe not found'),
  [ErrorCode.DappNotFound]: () => $gettext('DApp not found'),
  [ErrorCode.ContactNotFound]: () => $gettext('Contact not found'),
  [ErrorCode.Forbidden]: () => $gettext("You don't have enough rights"),
};

export class NotificationService {
  public static info(description: string, config?: Partial<NotificationArgsProps>) {
    const props: NotificationArgsProps = {
      message: $gettext('Info'),
      description,
      ...config,
    };

    notification.info(props);
  }

  public static success(description: string, config?: Partial<NotificationArgsProps>) {
    const props: NotificationArgsProps = {
      message: $gettext('Success'),
      description,
      ...config,
    };

    notification.success(props);
  }

  public static error(description: string, config?: Partial<NotificationArgsProps>) {
    const props: NotificationArgsProps = {
      message: $gettext('Error'),
      description,
      duration: 8,
      ...config,
    };

    notification.error(props);
  }

  public static warning(description: string, config?: Partial<NotificationArgsProps>) {
    const props: NotificationArgsProps = {
      message: $gettext('Warning'),
      description,
      duration: 8,
      ...config,
    };

    notification.warning(props);
  }

  public static processError(error: Error, defaultMessage = $gettext('Something went wrong.')) {
    if (error instanceof ReceiptStatusError) {
      this.error(error.message);
    } else if (error instanceof ApolloError) {
      const message = this.getGraphQLErrorMessage(error);
      this.error(message || defaultMessage);
    } else if (error instanceof WalletException) {
      this.error(error.message);
    } else if (error?.name === 'WalletIsClosedOrLocked') {
      this.error($gettext('Wallet is closed or locked'));
    } else if (StablecoinSDKService.isSDKError(error)) {
      const message = StablecoinSDKService.getSDKErrorMessage(error);
      this.error(message || defaultMessage);
    } else {
      this.error(defaultMessage);
    }
  }

  public static close(key: string) {
    notification.close(key);
  }

  private static getGraphQLErrorMessage(error: ApolloError) {
    const { code, payload, name } = error.graphQLErrors[0].extensions;

    if (name === 'DAppConfigurerException') {
      return error.message?.match(/"(.*?)"/)[1];
    }

    return code && errorMessages[code as string] ? errorMessages[code as string](payload) : null;
  }
}
