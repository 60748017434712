import { WALLET_CONNECT_PROJECT_ID, WALLET_CONNECT_RELAY_URL } from '@/config';
import type { NetworkEnum } from '@/graphql/generatedTypesAndHooks';
import { AvailableChains, ChainMap } from '@/models';
import { ExceptionHandler, NotificationService, WalletConnectServiceFactory } from '@/services';
import { useStablecoinSDKConnectionStore } from '@/stores/StablecoinSDKConnectionStore';
import { WalletConnectModal } from '@walletconnect/modal';
import SignClient, { WALLETCONNECT_DEEPLINK_CHOICE } from '@walletconnect/sign-client';
import type { SessionTypes } from '@walletconnect/types';
import { getSdkError } from '@walletconnect/utils';
import type { Store } from 'pinia';
import { defineStore } from 'pinia';
import { computed, onBeforeMount, ref } from 'vue';
import { useGettext } from 'vue3-gettext';

interface State {
  session: SessionTypes.Struct;
  multiChainAccountId: string;
}

interface Getters {
  network: NetworkEnum;
  accounts: string[];
  accountId: string;
}

interface Actions {
  connect: (network: NetworkEnum) => Promise<void>;
  disconnect: () => Promise<void>;
  changeAccount: (id: string) => void;
  getService: <T>() => T;
}

export type WalletConnectStoreType = Store<'walletConnect', State, Getters, Actions>;

export const useWalletConnectStore: () => WalletConnectStoreType = defineStore(
  'walletConnect',
  () => {
    const { $gettext } = useGettext();
    const stablecoinSDKConnectionStore = useStablecoinSDKConnectionStore();
    const modal = ref<WalletConnectModal>();
    const client = ref<SignClient>();
    const session = ref<SessionTypes.Struct>();
    const multiChainAccountId = ref<string>();

    const network = computed(() => (session.value ? getSessionNetwork(session.value) : null));
    const accounts = computed(() => (session.value ? getSessionAccounts(session.value) : []));
    const accountId = computed(() => multiChainAccountId.value?.split(':')[2]);

    onBeforeMount(async () => await init());

    async function init() {
      modal.value = new WalletConnectModal({
        projectId: WALLET_CONNECT_PROJECT_ID,
        chains: AvailableChains,
        explorerRecommendedWalletIds: [
          'a9104b630bac1929ad9ac2a73a17ed4beead1889341f307bff502f89b46c8501', // Blade
          'a29498d225fa4b13468ff4d6cf4ae0ea4adcbd95f07ce8a843a1dee10b632f3f', // Hashpack
          'c40c24b39500901a330a025938552d70def4890fffe9bd315046bd33a2ece24d', // Kabila
          'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', // Metamask
          '19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927', // Ledger Live
          'e9ff15be73584489ca4a66f64d32c4537711797e30b6660dbcb71ea72a42b1f4', // Exodus
          '15d7610042217f691385d20e640869dc7273e991b04e8f476417cdc5ec856955', // Coinomi
          'c8c8f44329b9b826ded9a2ac330745f584a61aed6b1d0ed2a093b64bca7fc3bb', // Abra
          'a21d06c656c8b1de253686e06fc2f1b3d4aa39c46df2bfda8a6cc524ef32c20c', // Venly
          '5864e2ced7c293ed18ac35e0db085c09ed567d67346ccb6f58a0327a75137489', // Fireblocks
        ],
      });
      client.value = await SignClient.init({
        projectId: WALLET_CONNECT_PROJECT_ID,
        relayUrl: WALLET_CONNECT_RELAY_URL,
        metadata: {
          name: 'Blade Console',
          url: window.location.origin,
          description: 'Blade Console is a decentralized crowdfunding platform',
          icons: [`${window.location.origin}/favicon.png`],
        },
      });

      client.value.on('session_expire', async (event) => await client.value.extend({ topic: event.topic }));
      client.value.on('session_update', ({ topic, params }) => {
        const { namespaces } = params;
        const currentSession = client.value.session.get(topic);
        session.value = { ...currentSession, namespaces };
      });
      client.value.on('session_delete', () => $reset());
      client.value.core.pairing.events.on('pairing_delete', () => $reset());

      await restoreLastSession();
    }

    async function connect(network: NetworkEnum) {
      try {
        await stablecoinSDKConnectionStore.disconnect();

        const { chainId, jsonRpcMethods } = ChainMap[network].walletConnect;
        const [namespace] = chainId.split(':');
        const { uri, approval } = await client.value.connect({
          requiredNamespaces: {
            [namespace]: {
              methods: jsonRpcMethods,
              chains: [chainId],
              events: [],
            },
          },
        });

        if (uri) {
          await modal.value.openModal({ uri });

          const newSession = await approval();

          if (session.value) {
            await disconnect();
          }

          session.value = newSession;
          multiChainAccountId.value = getSessionAccounts(newSession)[0];
        }
      } catch (e) {
        if (e.code === 1000) {
          NotificationService.error($gettext('User has rejected connection'));
        } else {
          NotificationService.processError(e, $gettext('Wallet Connect error. Please try again.'));
        }

        ExceptionHandler.handleError(e);
      } finally {
        modal.value.closeModal();
        localStorage.removeItem(WALLETCONNECT_DEEPLINK_CHOICE);
      }
    }

    async function disconnect() {
      try {
        await client.value.disconnect({
          topic: session.value.topic,
          reason: getSdkError('USER_DISCONNECTED'),
        });
      } catch (e) {
        // ignore
      } finally {
        $reset();
      }
    }

    function changeAccount(newAccountId: string) {
      if (!accounts.value.includes(newAccountId)) {
        return;
      }

      multiChainAccountId.value = newAccountId;
    }

    function getService<T>(): T {
      if (!client.value || !session.value || !multiChainAccountId.value) {
        throw new Error('Wallet not connected!');
      }

      return WalletConnectServiceFactory.getService<T>(client.value, session.value, multiChainAccountId.value);
    }

    async function restoreLastSession() {
      const sessions = client.value.session.getAll();
      const lastSession = sessions.length && sessions[sessions.length - 1];

      if (!lastSession || lastSession.expiry * 1000 <= Date.now()) {
        return;
      }

      const accounts = getSessionAccounts(lastSession);

      if (!accounts.includes(multiChainAccountId.value)) {
        multiChainAccountId.value = accounts[0];
      }

      session.value = lastSession;
    }

    function getSessionAccounts(session: SessionTypes.Struct) {
      return session.namespaces[Object.keys(session.requiredNamespaces)[0]].accounts;
    }

    function getSessionNetwork(session: SessionTypes.Struct) {
      return Object.keys(ChainMap).find(
        (networkEnum) =>
          ChainMap[networkEnum].walletConnect.chainId ===
          session.requiredNamespaces[Object.keys(session.requiredNamespaces)[0]].chains[0]
      ) as NetworkEnum;
    }

    const $reset = () => {
      session.value = null;
      multiChainAccountId.value = null;
    };

    return {
      session,
      network,
      multiChainAccountId,
      accounts,
      accountId,
      connect,
      disconnect,
      changeAccount,
      getService,
    };
  },
  {
    persist: {
      paths: ['multiChainAccountId'],
    },
  }
);
