import { GRAPHQL_ENDPOINT } from '@/config';
import { useUserStore } from '@/stores';
import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

const httpLink = createUploadLink({
  uri: GRAPHQL_ENDPOINT,
  fetch: async (uri: RequestInfo, options: RequestInit) => {
    const userStore = useUserStore();

    if (userStore.isExpired()) {
      await userStore.refreshToken();
    }

    if (userStore.currentUser?.accessToken) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${userStore.currentUser?.accessToken}`,
      };
    }
    return fetch(uri, options);
  },
});

const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
});
