import gettext from '@/gettext';
import type { Features } from '@/models';

const { $gettext } = gettext;

export const bladeLinkFeatures: Features[] = [
  {
    name: $gettext('Starting Balance'),
    description: $gettext('Greet new users with a preset token balance to spark their engagement right away.'),
    isComingSoon: false,
    preview: true,
    detailed: true,
  },
  {
    name: $gettext('NFT Drop'),
    description: $gettext('Seamlessly integrate exclusive NFTs into the user journey.'),
    isComingSoon: false,
    preview: true,
    detailed: true,
  },
  {
    name: $gettext('Activity Statistics'),
    description: $gettext('Monitor user engagement to refine strategies and user interactions.'),
    isComingSoon: false,
    preview: true,
    detailed: true,
  },
  {
    name: $gettext('Upcoming Features'),
    description: $gettext(
      'Enhanced user capture, professional landing page design, and liquidity pool setups to further enrich the onboarding experience.'
    ),
    isComingSoon: false,
    preview: false,
    detailed: true,
  },
  {
    name: $gettext('Liquidity Pool setup'),
    isComingSoon: true,
    preview: true,
    detailed: false,
  },
];
