import type { NetworkEnum } from '@/graphql/generatedTypesAndHooks';
import { ApiService } from '@/services/ApiService';
import { ExceptionHandler } from '@/services/ExceptionHandler';
import { AbiCoder, Interface } from '@ethersproject/abi';
import {
  ContractCreateTransaction,
  ContractFunctionParameters,
  Hbar,
  Transaction,
  TransactionId,
} from '@hashgraph/sdk';
import { Buffer } from 'buffer';

export class SmartContractService {
  public static createSmartContract(
    accountId: string,
    fileId: string,
    params: ContractFunctionParameters,
    gas: number,
    initialBalance?: Hbar
  ): Transaction {
    const transaction = new ContractCreateTransaction()
      .setBytecodeFileId(fileId)
      .setConstructorParameters(params)
      .setTransactionId(TransactionId.generate(accountId))
      .setGas(gas);

    if (initialBalance != null && !initialBalance.toBigNumber().isZero()) {
      transaction.setInitialBalance(initialBalance);
    }

    return transaction;
  }

  public static async getContractNFTMetadata(network: NetworkEnum, contractId: string): Promise<string> {
    const abi = new Interface(['function getMetadata()']);
    const data = abi.encodeFunctionData('getMetadata');

    try {
      const response = await ApiService.callContract(network, contractId, data);
      const result = response?.result || '';

      const [[hex]] = new AbiCoder().decode(['bytes[] memory'], result);
      return Buffer.from(hex.substring(2), 'hex').toString();
    } catch (e) {
      ExceptionHandler.handleError(e);

      return null;
    }
  }
}
