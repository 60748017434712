import type { FormInstance } from 'ant-design-vue/es/form';
import type { ValidateErrorEntity } from 'ant-design-vue/es/form/interface';
import type { Ref } from 'vue';

export function useSubmitForm<T>(
  formRef: Ref<FormInstance | undefined>,
  onFinish?: (v: T) => void,
  onFinishFailed?: (e: ValidateErrorEntity<T>) => void
): () => void {
  return () => {
    formRef
      .value!.validate()
      .then((v) => onFinish && onFinish(v as T))
      .catch((e) => onFinishFailed && onFinishFailed(e));
  };
}
