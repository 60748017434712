import { IDLE_LOGOUT_TIMEOUT, IDLE_NOTIFICATION_TIMEOUT, LAST_ACTIVITY_KEY, LAST_ACTIVITY_VALUE } from '@/config';
import { LOGOUT_SCREEN_ROUTE_NAME } from '@/router';
import { NotificationService } from '@/services';
import { useUserStore } from '@/stores';
import { getCookie, setCookie } from '@/utils';
import { useIdle } from '@vueuse/core';
import { getAuth } from 'firebase/auth';
import { watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useGettext } from 'vue3-gettext';

export function useInactivityHandler(): void {
  const { $gettext } = useGettext();
  const route = useRoute();
  const router = useRouter();
  const auth = getAuth();
  const userStore = useUserStore();
  const debounceTime = 5000;

  if (getCookie(LAST_ACTIVITY_KEY) === null) {
    auth.signOut().then(userStore.$reset);
  }

  const { idle: setCookieDebounce } = useIdle(debounceTime);
  const { idle: idleNotification } = useIdle(IDLE_NOTIFICATION_TIMEOUT);
  const { idle: idleLogout } = useIdle(IDLE_LOGOUT_TIMEOUT);
  const key = 'INACTIVITY_NOTIFICATION_KEY';

  watch(setCookieDebounce, async (value) => {
    if (value && userStore.user) {
      setCookie(LAST_ACTIVITY_KEY, LAST_ACTIVITY_VALUE, IDLE_LOGOUT_TIMEOUT - debounceTime);
    }
  });

  watch(idleNotification, (value) => {
    if (value && userStore.user) {
      NotificationService.info($gettext("You'll be logged out soon due to inactivity"), {
        duration: null,
        closeIcon: '',
        key,
      });
    } else {
      NotificationService.close(key);
    }
  });

  watch(idleLogout, async (value) => {
    if (value && !route.meta?.requiresAuth && userStore.user) {
      NotificationService.close(key);
      await auth.signOut();
      userStore.$reset();
      return;
    }

    if (value && route.meta?.requiresAuth && userStore.user) {
      NotificationService.close(key);
      await router.push({ name: LOGOUT_SCREEN_ROUTE_NAME });
    }
  });
}
