<script setup lang="ts">
import { useInactivityHandler } from '@/functions';
import { useUserStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const userStore = useUserStore();
const router = useRouter();
const { user } = storeToRefs(userStore);
const userLoad = ref(false);

useInactivityHandler();

router.afterEach((to) => {
  if (!to.meta?.requiresAuth && !userLoad.value) {
    userLoad.value = true;
  }
});

watch(
  () => user.value?.organization?.name,
  () => {
    if (user.value?.organization?.name) {
      userLoad.value = true;
    }
  },
  { immediate: true }
);
</script>

<template>
  <a-config-provider
    :theme="{
      token: {
        borderRadius: 4,
        colorPrimary: '#442ed1',
        colorBgLayout: '#f0f2f5',
      },
      components: {
        Card: {
          borderRadiusLG: 16,
        },
        Notification: {
          borderRadiusLG: 16,
        },
        Modal: {
          borderRadiusLG: 16,
        },
      },
    }"
  >
    <RouterView v-if="userLoad" />
    <div v-else class="loader">
      <a-spin size="large" class="spin" />
    </div>
  </a-config-provider>
</template>

<style lang="less" scoped>
.spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader {
  background: #f0f2f5;
  height: 100vh;
}
</style>
