<script setup lang="ts">
import RequestResetPasswordForm from '@/components/forms/RequestResetPasswordForm/RequestResetPasswordForm.vue';
import type { RequestResetPasswordFormModel } from '@/components/forms/RequestResetPasswordForm/RequestResetPasswordFormModel';
import { useIsValidForm } from '@/functions';
import { useResetPasswordMutation } from '@/graphql/generatedTypesAndHooks';
import { ExceptionHandler, NotificationService } from '@/services';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useGettext } from 'vue3-gettext';
import { LOGIN_SCREEN_ROUTE_NAME } from '@/router';

const data = ref<RequestResetPasswordFormModel>({ email: '' });
const formRef = ref<InstanceType<typeof RequestResetPasswordForm>>();
const isValidForm = useIsValidForm(formRef, data);
const { $gettext } = useGettext();
const router = useRouter();

const { mutate, loading } = useResetPasswordMutation();

const submit = async () => {
  try {
    await mutate({
      reset: {
        email: data.value.email,
      },
    });

    NotificationService.success(
      $gettext(
        'If there is an account associated with %{ email } you will receive an email with a link to reset your password.',
        { email: data.value.email }
      )
    );
    await router.push({ name: LOGIN_SCREEN_ROUTE_NAME });
  } catch (e) {
    // maybe we should handle this error in a different way like code
    NotificationService.error($gettext('Something went wrong.'));
    NotificationService.processError(e);
    ExceptionHandler.handleError(e);
  }
};
</script>

<template>
  <a-row align="middle" justify="space-around" class="requestResetPasswordView">
    <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8" :xxl="6">
      <a-card :title="$gettext('Reset Your Password')">
        <div class="description">
          {{ $gettext('Submit your email to get a reset link and regain account access.') }}
        </div>
        <br />
        <RequestResetPasswordForm ref="formRef" @enter="submit" v-model:value="data" />

        <div>
          <a-button :disabled="!isValidForm" :loading="loading" type="primary" @click="submit">
            {{ $gettext('Submit') }}
          </a-button>
        </div>
      </a-card>
    </a-col>
  </a-row>
</template>

<style scoped lang="less">
@import '@/assets/variables';

.requestResetPasswordView {
  height: calc(100vh - @header-height * 4);
}
</style>
