<script setup lang="ts">
import {
  APP_VERSION,
  COOKIE_POLICY_LINK,
  CUSTOMER_SUPPORT_LINK,
  PRIVACY_POLICY_LINK,
  TERMS_OF_USE_LINK,
} from '@/config';
import { DISCLAIMER_ROUTE_NAME } from '@/router';
import { useRouter } from 'vue-router';

const router = useRouter();
</script>

<template>
  <a-layout-footer class="footer">
    <a-row justify="space-between" :gutter="[8, 8]">
      <a-col class="f-size-12 lh-20 secondary">
        {{ $gettext('App version') }}
        {{ APP_VERSION }}
      </a-col>
      <a-col>
        <a-row :gutter="[24, 8]">
          <a-col>
            <a :href="CUSTOMER_SUPPORT_LINK" target="_blank" class="lh-22 secondary">
              {{ $gettext('Customer Support') }}
            </a>
          </a-col>
          <a-col>
            <a
              @click="router.push({ name: DISCLAIMER_ROUTE_NAME })"
              target="_blank"
              class="lh-22 secondary footer__link"
            >
              {{ $gettext('Disclaimers') }}
            </a>
          </a-col>
          <a-col>
            <a :href="TERMS_OF_USE_LINK" target="_blank" class="lh-22 secondary">
              {{ $gettext('Terms of Use') }}
            </a>
          </a-col>
          <a-col>
            <a :href="PRIVACY_POLICY_LINK" target="_blank" class="lh-22 secondary">
              {{ $gettext('Privacy policy') }}
            </a>
          </a-col>
          <a-col>
            <a :href="COOKIE_POLICY_LINK" target="_blank" class="lh-22 secondary">
              {{ $gettext('Cookie policy') }}
            </a>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </a-layout-footer>
</template>

<style scoped lang="less">
@import '@/assets/variables';

.footer {
  padding: 16px 24px;
}
</style>
