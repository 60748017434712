<script setup lang="ts">
import { useSubmitForm, useValidateForm } from '@/functions';
import { requiredRule } from '@/validation';
import { reactive, ref, watch } from 'vue';
import { useGettext } from 'vue3-gettext';
import type { FormInstance } from 'ant-design-vue/es/form';
import Schema, { type Rule, type Rules } from 'async-validator';
import type { RequestResetPasswordFormModel } from 'src/components/forms/RequestResetPasswordForm/RequestResetPasswordFormModel';

interface Props {
  value: RequestResetPasswordFormModel;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'update:value', value: RequestResetPasswordFormModel): void;
  (e: 'enter'): void;
}>();
const formRef = ref<FormInstance>();
const initialModel: RequestResetPasswordFormModel = {
  email: '',
};
const formModel = reactive<RequestResetPasswordFormModel>({ ...initialModel, ...props.value });
const { $gettext } = useGettext();

const descriptor: Rules = {
  email: [requiredRule, { type: 'email', message: $gettext('Invalid email address.') }],
} as Record<keyof RequestResetPasswordFormModel, Rule>;
const validator = new Schema(descriptor);
const validateForm = useValidateForm(formModel, reactive(validator.rules));
const onFinish = (v: RequestResetPasswordFormModel) => emit('update:value', v);
const submitForm = useSubmitForm(formRef, onFinish);

watch(
  formModel,
  (v) => {
    emit('update:value', v);
  },
  { deep: true }
);

defineExpose({ submitForm, validateForm });
</script>

<template>
  <a-form
    hideRequiredMark
    ref="formRef"
    layout="vertical"
    validateTrigger="blur"
    :model="formModel"
    :rules="validator.rules"
  >
    <a-form-item name="email" :label="$gettext('Email')">
      <a-input
        @keyup.enter="emit('enter')"
        v-model:value="formModel.email"
        type="text"
        :placeholder="$gettext('e.g user@bladelabs.io')"
        autocomplete="off"
      />
    </a-form-item>
  </a-form>
</template>
