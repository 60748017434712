<script setup lang="ts">
import { DAppsTabs } from '@/components/DApps/DAppsTabs';
import { CAMPAIGNS_SCREEN_ROUTE_NAME } from '@/router';
import { useGettext } from 'vue3-gettext';

const { $gettext } = useGettext();
</script>

<template>
  <div>
    <router-link :to="{ name: CAMPAIGNS_SCREEN_ROUTE_NAME, params: { tab: DAppsTabs.Overview } }">
      <a class="lh-22 secondary">{{ $gettext('Back to Campaigns') }}</a>
    </router-link>
  </div>
</template>
