<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useGettext } from 'vue3-gettext';

const message = ref<string>('');
const route = useRoute();
const { $gettext } = useGettext();

onBeforeMount(() => {
  message.value = (route?.query?.errorMessage as string) || $gettext('Something went wrong');
});
</script>

<template>
  <div class="dropFailureView">
    <a-result status="error" :title="$gettext('Oops!')" :sub-title="message" />
  </div>
</template>

<style scoped lang="less">
@import '@/assets/variables';

.dropFailureView {
  background: @blade-background-base;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
