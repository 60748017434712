<script setup lang="ts">
import ServiceBlock from '@/components/Service/ServiceBlock.vue';
import { type RouteLocationRaw, useRouter } from 'vue-router';

interface Props {
  title: string;
  image: string;
  route?: RouteLocationRaw;
}

const props = defineProps<Props>();
const router = useRouter();

const goTo = () => {
  if (props.route) {
    router.push(props.route);
  }
};
</script>

<template>
  <ServiceBlock class="serviceUseCaseCard">
    <div class="serviceUseCaseCard__card">
      <div class="serviceUseCaseCard__block">
        <div class="serviceUseCaseCard__title">
          {{ props.title }}
        </div>
        <div class="serviceUseCaseCard__content">
          <slot></slot>
        </div>
      </div>
      <div class="serviceUseCaseCard__button" v-if="props.route">
        <a-button type="default" @click="goTo()">
          {{ $gettext('Learn More') }}
        </a-button>
      </div>
    </div>
    <div class="serviceUseCaseCard__image">
      <img :src="props.image" :alt="props.title" />
    </div>
  </ServiceBlock>
</template>

<style lang="less">
.serviceUseCaseCard {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: initial;
  align-items: flex-start;
  flex: 1 0 0;

  &__image {
    border-radius: 8px;
    margin-left: 16px;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    height: 100%;
    min-width: 200px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    height: 100%;
  }

  &__button {
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
</style>
