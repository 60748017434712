import { NetworkEnum } from '@/graphql/generatedTypesAndHooks';
import { HbarTokenId } from '@/models/nativeTokens/Hbar';
import { Network } from '@/models/Networks';
import { HederaJsonRpcMethod } from '@hashgraph/hedera-wallet-connect';
import { Networks } from '@stellar/stellar-sdk';

export enum CryptoKeyType {
  ED25519 = 'ED25519',
  ECDSA_SECP256K1 = 'ECDSA_SECP256K1',
}

export type ChainConfig = {
  name: string;
  isTestnet: boolean;
  nativeTokenId: string;
  currency: string;
  coinName: string;
  decimals: string;
  iconPath: string;
  nativeNetworkEnum: string;
  mintFunctionName: string;
  mintGasAmount: number;
  defaultCryptoKeyType: CryptoKeyType;
  explorerUrl: string;
  mirrorNodeUrl: string;
  rpcNodeUrl: string;
  walletConnect: {
    chainId: string;
    serviceName: string;
    jsonRpcMethods: string[];
  };
};

export const ChainMap: Partial<Record<NetworkEnum, ChainConfig>> = {
  [NetworkEnum.HederaMainnet]: {
    name: 'Hedera Mainnet',
    isTestnet: false,
    nativeTokenId: HbarTokenId,
    currency: 'HBAR',
    coinName: 'Hedera HBAR',
    decimals: '8',
    iconPath: 'hedera',
    mintFunctionName: 'mintNFT',
    mintGasAmount: 900000,
    nativeNetworkEnum: Network.Mainnet,
    defaultCryptoKeyType: CryptoKeyType.ED25519,
    explorerUrl: 'https://hashscan.io/mainnet/',
    mirrorNodeUrl: 'https://mainnet-public.mirrornode.hedera.com/',
    rpcNodeUrl: 'https://mainnet.hashio.io/',
    walletConnect: {
      chainId: 'hedera:mainnet',
      serviceName: 'HederaWalletConnectService',
      jsonRpcMethods: Object.values(HederaJsonRpcMethod),
    },
  },
  [NetworkEnum.HederaTestnet]: {
    name: 'Hedera Testnet',
    isTestnet: true,
    nativeTokenId: HbarTokenId,
    currency: 'HBAR',
    coinName: 'Hedera HBAR',
    decimals: '8',
    iconPath: 'hedera',
    mintFunctionName: 'mintNFT',
    mintGasAmount: 900000,
    nativeNetworkEnum: Network.Testnet,
    defaultCryptoKeyType: CryptoKeyType.ED25519,
    explorerUrl: 'https://hashscan.io/testnet/',
    mirrorNodeUrl: 'https://testnet.mirrornode.hedera.com/',
    rpcNodeUrl: 'https://testnet.hashio.io/',
    walletConnect: {
      chainId: 'hedera:testnet',
      serviceName: 'HederaWalletConnectService',
      jsonRpcMethods: Object.values(HederaJsonRpcMethod),
    },
  },
  [NetworkEnum.StellarMainnet]: {
    name: 'Stellar Public',
    isTestnet: false,
    nativeTokenId: 'XLM',
    currency: 'XLM',
    coinName: 'Stellar XLM',
    decimals: '7',
    iconPath: 'stellar',
    mintFunctionName: '',
    mintGasAmount: 0,
    nativeNetworkEnum: Networks.PUBLIC,
    defaultCryptoKeyType: CryptoKeyType.ED25519,
    explorerUrl: 'https://stellar.expert/explorer/public/',
    mirrorNodeUrl: 'https://horizon.stellar.org/',
    rpcNodeUrl: '',
    walletConnect: {
      chainId: 'stellar:pubnet',
      serviceName: 'StellarWalletConnectService',
      jsonRpcMethods: ['stellar_signXDR', 'stellar_signAndSubmitXDR'],
    },
  },
  [NetworkEnum.StellarTestnet]: {
    name: 'Stellar Testnet',
    isTestnet: true,
    nativeTokenId: 'XLM',
    currency: 'XLM',
    coinName: 'Stellar XLM',
    decimals: '7',
    iconPath: 'stellar',
    mintFunctionName: '',
    mintGasAmount: 0,
    nativeNetworkEnum: Networks.TESTNET,
    defaultCryptoKeyType: CryptoKeyType.ED25519,
    explorerUrl: 'https://stellar.expert/explorer/testnet/',
    mirrorNodeUrl: 'https://horizon-testnet.stellar.org/',
    rpcNodeUrl: '',
    walletConnect: {
      chainId: 'stellar:testnet',
      serviceName: 'StellarWalletConnectService',
      jsonRpcMethods: ['stellar_signXDR', 'stellar_signAndSubmitXDR'],
    },
  },
  // [NetworkEnum.EthereumMainnet]: {
  //   name: 'Ethereum Mainnet',
  //   isTestnet: false,
  //   currency: 'ETH',
  //   coinName: 'Ethereum ETH',
  //   decimals: '18',
  //   nativeNetworkEnum: '',
  //   defaultCryptoKeyType: CryptoKeyType.ECDSA_SECP256K1,
  //   explorerUrl: 'https://etherscan.io/',
  //   mirrorNodeUrl: '',
  //   rpcNodeUrl: '',
  //   walletConnect: {
  //     chainId: 'eip155:1',
  //     serviceName: 'EthereumWalletConnectService',
  //     jsonRpcMethods: [
  //       'personal_sign',
  //       'eth_sign',
  //       'eth_signTypedData',
  //       'eth_sendTransaction',
  //       'eth_signTransaction',
  //       'eth_sendRawTransaction',
  //     ],
  //   },
  // },
  // [NetworkEnum.EthereumSepolia]: {
  //   name: 'Ethereum Sepolia',
  //   isTestnet: true,
  //   currency: 'ETH',
  //   coinName: 'Ethereum ETH',
  //   decimals: '18',
  //   nativeNetworkEnum: '',
  //   defaultCryptoKeyType: CryptoKeyType.ECDSA_SECP256K1,
  //   explorerUrl: 'https://sepolia.etherscan.io/',
  //   mirrorNodeUrl: '',
  //   rpcNodeUrl: '',
  //   walletConnect: {
  //     chainId: 'eip155:11155111',
  //     serviceName: 'EthereumWalletConnectService',
  //     jsonRpcMethods: [
  //       'personal_sign',
  //       'eth_sign',
  //       'eth_signTypedData',
  //       'eth_sendTransaction',
  //       'eth_signTransaction',
  //       'eth_sendRawTransaction',
  //     ],
  //   },
  // },
};

export const AvailableChains = Object.keys(ChainMap) as NetworkEnum[];
export const MainnetChains = AvailableChains.filter((chain) => !ChainMap[chain].isTestnet);
export const TestnetChains = AvailableChains.filter((chain) => ChainMap[chain].isTestnet);
