import type { NetworkEnum } from '@/graphql/generatedTypesAndHooks';
import type { Token } from '@/models';
import { HederaTokenService, StellarTokenService } from '@/services';
import type { TokenId } from '@hashgraph/sdk';
import type { AbstractTokenService } from './AbstractTokenService';

class TokenService implements Omit<AbstractTokenService, 'chains'> {
  private services: AbstractTokenService[];

  constructor(services: AbstractTokenService[]) {
    this.services = services;
  }

  public async getTokenInfo(
    tokenId: string | TokenId,
    network: NetworkEnum,
    contractId?: string
  ): Promise<Token | null> {
    return this.getService(network).getTokenInfo(tokenId, network, contractId);
  }

  private getService(network: NetworkEnum): AbstractTokenService {
    const service = this.services.find((service) => service.chains.includes(network));

    if (!service) {
      throw new Error(`No token service found for network ${network}`);
    }

    return service;
  }
}

export const TokenServiceAdapter = new TokenService([HederaTokenService, StellarTokenService]);
