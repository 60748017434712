import { StellarClientFactory } from '@/client';
import type { NetworkEnum } from '@/graphql/generatedTypesAndHooks';
import { ChainMap } from '@/models';
import { Asset, Memo, Operation, TransactionBuilder } from '@stellar/stellar-sdk';

export class StellarTransactionService {
  static async createXlmPaymentTransaction(
    amount: string,
    ownerAccountId: string,
    destinationAccountId: string,
    network: NetworkEnum
  ) {
    const client = StellarClientFactory.getClient(network);
    const account = await client.loadAccount(ownerAccountId);
    const fee = await client.fetchBaseFee();

    const transaction = new TransactionBuilder(account, {
      fee: fee.toString(),
      networkPassphrase: ChainMap[network].nativeNetworkEnum,
    })
      .addOperation(
        Operation.payment({
          destination: destinationAccountId,
          asset: Asset.native(),
          amount,
        })
      )
      .setTimeout(30)
      .addMemo(Memo.text('Blade Console allowance'))
      .build();

    return transaction;
  }
}
