import { Capability } from '@/graphql/generatedTypesAndHooks';
import { defineStore, storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useUserStore } from './UserStore';

export const useCapabilitiesStore = defineStore('capabilitiesStore', () => {
  const { currentUser } = storeToRefs(useUserStore());

  const canCreateCampaign = computed<boolean>(() =>
    currentUser.value?.capabilities?.includes(Capability.CreateCampaign)
  );
  const canUpdateCampaign = computed<boolean>(() =>
    currentUser.value?.capabilities?.includes(Capability.UpdateCampaign)
  );

  return {
    canCreateCampaign,
    canUpdateCampaign,
  };
});
