import type { NetworkEnum } from '@/graphql/generatedTypesAndHooks';
import { Balance } from '@/models';
import { AbstractBalanceService } from './AbstractBalanceService';
import { HederaBalanceService } from './hedera';
import { StellarBalanceService } from './stellar';

export class BalanceService implements Omit<AbstractBalanceService, 'chains'> {
  private services: AbstractBalanceService[];

  constructor(services: AbstractBalanceService[]) {
    this.services = services;
  }

  async fetchNativeBalance(accountId: string, network: NetworkEnum): Promise<Balance> {
    return this.getService(network).fetchNativeBalance(accountId, network);
  }

  async fetchTokenBalances(accountId: string, network: NetworkEnum, tokenId?: string): Promise<Balance[]> {
    return this.getService(network).fetchTokenBalances(accountId, network, tokenId);
  }

  private getService(network: NetworkEnum): AbstractBalanceService {
    const service = this.services.find((service) => service.chains.includes(network));

    if (!service) {
      throw new Error(`No balance service found for network ${network}`);
    }

    return service;
  }
}

export const BalanceServiceAdapter = new BalanceService([HederaBalanceService, StellarBalanceService]);
