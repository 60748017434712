<script setup lang="ts">
import ServiceBlock from '@/components/Service/ServiceBlock.vue';
import {
  C_SDK_DEMO_LINK,
  C_SDK_DOC_LINK,
  C_SDK_REPO_LINK,
  JS_SDK_DOC_LINK,
  JS_SDK_REPO_LINK,
  KOTLIN_SDK_DEMO_LINK,
  KOTLIN_SDK_DOC_LINK,
  KOTLIN_SDK_REPO_LINK,
  SDK_DOC,
  SWIFT_SDK_DOC_LINK,
  SWIFT_SDK_REPO_LINK,
  UNITY_SDK_DEMO_LINK,
  UNITY_SDK_DOC_LINK,
  UNITY_SDK_REPO_LINK,
} from '@/config';
import { useGettext } from 'vue3-gettext';

interface SDKSetting {
  name: string;
  description: string;
  repoLink: string;
  docLink: string;
  demoLink?: string;
}

const { $gettext } = useGettext();

const sdks: SDKSetting[] = [
  {
    name: 'JS',
    description: $gettext('Browser-based apps'),
    repoLink: JS_SDK_REPO_LINK,
    docLink: JS_SDK_DOC_LINK,
  },
  {
    name: 'Swift',
    description: $gettext('Native iOS apps'),
    repoLink: SWIFT_SDK_REPO_LINK,
    docLink: SWIFT_SDK_DOC_LINK,
  },
  {
    name: 'Kotlin',
    description: $gettext('Native Android app'),
    repoLink: KOTLIN_SDK_REPO_LINK,
    docLink: KOTLIN_SDK_DOC_LINK,
    demoLink: KOTLIN_SDK_DEMO_LINK,
  },
  {
    name: 'Unity',
    description: $gettext('Beta'),
    repoLink: UNITY_SDK_REPO_LINK,
    docLink: UNITY_SDK_DOC_LINK,
    demoLink: UNITY_SDK_DEMO_LINK,
  },
  {
    name: 'C++',
    description: $gettext('Beta'),
    repoLink: C_SDK_REPO_LINK,
    docLink: C_SDK_DOC_LINK,
    demoLink: C_SDK_DEMO_LINK,
  },
];
</script>

<template>
  <ServiceBlock :title="$gettext('Documentation links')" class="serviceSdkDoc">
    <div v-for="sdk in sdks" :key="sdk.name" class="serviceSdkDoc__block">
      <span class="serviceSdkDoc__title">{{ sdk.name }}</span>
      <span class="serviceSdkDoc__description">{{ sdk.description }}</span>
      <div>
        <a-button class="serviceSdkDoc__button" type="link" :href="sdk.docLink" target="_blank">
          {{ $gettext('Read the docs') }}
        </a-button>
        <a-button class="serviceSdkDoc__button" type="link" :href="sdk.repoLink" target="_blank">
          {{ $gettext('GitHub') }}
        </a-button>
        <a-button class="serviceSdkDoc__button" v-if="sdk.demoLink" type="link" :href="sdk.demoLink" target="_blank">
          {{ $gettext('Demo app') }}
        </a-button>
      </div>
      <a-divider class="serviceSdkDoc__divider" />
    </div>
    <div class="text-center full-width">
      <a-button type="default" :href="SDK_DOC" target="_blank">
        {{ $gettext('Check all supported features across SDKs') }}
      </a-button>
    </div>
  </ServiceBlock>
</template>

<style scoped lang="less">
.serviceSdkDoc {
  gap: 8px;

  &__block {
    width: 100%;
  }

  &__divider {
    margin: 4px 0;
  }

  &__button {
    padding: 0;
    margin-right: 16px;
    margin-top: 5px;
  }

  &__title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-right: 8px;
  }

  &__description {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    position: relative;
    top: -1px;
  }
}
</style>
