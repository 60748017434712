import { asyncWithTimeout } from '@/utils';
import type SignClient from '@walletconnect/sign-client';
import type { SessionTypes } from '@walletconnect/types';

export abstract class AbstractWalletConnectService {
  protected readonly client: SignClient;
  protected readonly session: SessionTypes.Struct;
  protected readonly accountId: string;
  protected readonly chainId: string;

  protected constructor(client: SignClient, session: SessionTypes.Struct, accountId: string) {
    this.client = client;
    this.session = session;
    this.accountId = accountId;
    this.chainId = this.getChainIdFromSession(session);
  }

  protected async requestWrapper<T>(request: Promise<T>): Promise<T> {
    if (this.session.peer.metadata.name === 'Blade Wallet') {
      try {
        await asyncWithTimeout(window.bladeConnect?.wake(), 5000);
      } catch (err) {
        console.warn('Failed to wake wallet', err);
      }
    }

    return await asyncWithTimeout(request, 60000);
  }

  private getChainIdFromSession(session: SessionTypes.Struct): string {
    // currently, only one chain can be connected at a time
    return session.requiredNamespaces[Object.keys(session.requiredNamespaces)[0]].chains[0];
  }
}
