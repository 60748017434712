import type { NetworkEnum } from '@/graphql/generatedTypesAndHooks';
import { ChainMap, TokenType } from '../';
import { Token } from '../Token';

export const HbarTokenId = '0.0.0';

export class Hbar extends Token {
  constructor(network: NetworkEnum) {
    super(
      ChainMap[network].nativeTokenId,
      network,
      'Hedera HBAR',
      'HBAR',
      '8',
      TokenType.Fungible,
      null,
      50000000000,
      50000000000,
      50000000000
    );
  }
}
