import type { NetworkEnum } from '@/graphql/generatedTypesAndHooks';
import type { Balance } from '@/models';

export abstract class AbstractBalanceService {
  abstract chains: NetworkEnum[];

  abstract fetchNativeBalance(accountId: string, network: NetworkEnum): Promise<Balance>;

  abstract fetchTokenBalances(accountId: string, network: NetworkEnum, tokenId?: string): Promise<Balance[]>;
}
