<script setup lang="ts">
import { useGettext } from 'vue3-gettext';
import { useRoute, useRouter } from 'vue-router';

const { $gettext } = useGettext();
const route = useRoute();
const router = useRouter();

const exitWizard = () => {
  const currentPathArray = route.fullPath.split('/');
  const newPathArray = currentPathArray.slice(0, currentPathArray.length - 1);
  router.push({ path: newPathArray.join('/') });
};
</script>

<template>
  <a class="lh-22 secondary" @click="exitWizard()">{{ $gettext('Exit Wizard') }}</a>
</template>
