<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';
import { useCreateEnterpriseCheckoutSessionMutation } from '@/graphql/generatedTypesAndHooks';
import { ExceptionHandler } from '@/services';
import { useGettext } from 'vue3-gettext';
import { useRoute } from 'vue-router';

enum ViewState {
  LOADING,
  ERROR,
}

const viewState = ref<ViewState>(ViewState.LOADING);
const { $gettext } = useGettext();
const route = useRoute();
const { mutate, onDone, onError } = useCreateEnterpriseCheckoutSessionMutation();

onError((e) => {
  ExceptionHandler.handleError(e);
  viewState.value = ViewState.ERROR;
});

onDone((result) => {
  window.location.href = result.data.createEnterpriseCheckoutSession.url;
});

onBeforeMount(() => {
  mutate({ input: { deferredCheckoutSessionId: route.params.sessionId as string } });
});
</script>

<template>
  <div class="checkoutView">
    <div class="checkoutView__content">
      <template v-if="viewState === ViewState.LOADING">
        <div class="f-size-16 f-weight-500">{{ $gettext('Redirecting to the checkout page') }}</div>
        <div>
          <a-spin />
        </div>
      </template>
      <template v-else-if="viewState === ViewState.ERROR">
        <a-result
          status="error"
          :subTitle="
            $gettext('Oops! Error redirecting to the checkout page. Please try again and if error persists contact us.')
          "
        />
      </template>
    </div>
  </div>
</template>

<style scoped lang="less">
.checkoutView {
  height: calc(100vh - 80px);

  &__content {
    display: flex;
    row-gap: 22px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 80px);
  }
}
</style>
