<script setup lang="ts">
import { useSubmitForm, useValidateForm } from '@/functions';
import { passwordMinLengthRule, passwordStrengthRule, requiredRule } from '@/validation';
import { reactive, ref, watch } from 'vue';
import { useGettext } from 'vue3-gettext';
import type { FormInstance } from 'ant-design-vue/es/form';
import Schema, { type Rule, type Rules } from 'async-validator';
import type { ResetPasswordFormModel } from './ResetPasswordFormModel';

interface Props {
  value: ResetPasswordFormModel;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'update:value', value: ResetPasswordFormModel): void;
  (e: 'enter'): void;
}>();
const formRef = ref<FormInstance>();
const initialModel: ResetPasswordFormModel = {
  password: '',
  confirmPassword: '',
};
const formModel = reactive<ResetPasswordFormModel>({ ...initialModel, ...props.value });
const { $gettext } = useGettext();

const descriptor: Rules = {
  password: [requiredRule, passwordMinLengthRule, passwordStrengthRule],
  confirmPassword: [
    requiredRule,
    {
      validator: (rule, value) => (value === formModel.password ? Promise.resolve() : Promise.reject()),
      message: $gettext('The two passwords that you entered do not match!'),
    },
  ],
} as Record<keyof ResetPasswordFormModel, Rule>;
const validator = new Schema(descriptor);
const validateForm = useValidateForm(formModel, reactive(validator.rules));
const onFinish = (v: ResetPasswordFormModel) => emit('update:value', v);
const submitForm = useSubmitForm(formRef, onFinish);

watch(
  formModel,
  (v) => {
    emit('update:value', v);
  },
  { deep: true }
);

defineExpose({ submitForm, validateForm });
</script>

<template>
  <a-form
    hideRequiredMark
    ref="formRef"
    layout="vertical"
    validateTrigger="blur"
    :model="formModel"
    :rules="validator.rules"
  >
    <a-form-item name="password" :label="$gettext('Password')">
      <a-input-password
        @keyup.enter="emit('enter')"
        v-model:value="formModel.password"
        type="text"
        :placeholder="$gettext('Password')"
        autocomplete="off"
      />
    </a-form-item>
    <a-form-item name="confirmPassword" :label="$gettext('Confirmation Password')">
      <a-input-password
        @keyup.enter="emit('enter')"
        v-model:value="formModel.confirmPassword"
        autocomplete="off"
        :placeholder="$gettext('Confirmation Password')"
      />
    </a-form-item>
  </a-form>
</template>
