import { ChainMap } from '@/models';
import type SignClient from '@walletconnect/sign-client';
import type { SessionTypes } from '@walletconnect/types';

import * as Services from './';

export class WalletConnectServiceFactory {
  public static getService<T>(client: SignClient, session: SessionTypes.Struct, accountId: string): T {
    const chainId = session.requiredNamespaces[Object.keys(session.requiredNamespaces)[0]].chains[0];
    const chainConfig = Object.values(ChainMap).find((c) => c.walletConnect.chainId === chainId);

    return new Services[chainConfig.walletConnect.serviceName](client, session, accountId);
  }
}
