import { NetworkEnum } from '@/graphql/generatedTypesAndHooks';
import { ChainMap, Token, Xlm } from '@/models';
import type { TokenId } from '@hashgraph/sdk';
import { AbstractTokenService } from '../AbstractTokenService';

class TokenService extends AbstractTokenService {
  chains: NetworkEnum[] = [NetworkEnum.StellarMainnet, NetworkEnum.StellarTestnet];

  public async getTokenInfo(
    tokenId: string | TokenId,
    network: NetworkEnum,
    contractId?: string // eslint-disable-line @typescript-eslint/no-unused-vars
  ): Promise<Token | null> {
    if (tokenId === ChainMap[network].nativeTokenId) {
      return new Xlm(network);
    }
  }
}

export const StellarTokenService = new TokenService();
